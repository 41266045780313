<template>
  <div class="criteria-options">
    <input
      v-model="value.field"
      class="form-control field"
      type="text"
      :placeholder="$t('propertyNamePlaceholder')"
      :disabled="readOnly"
    />

    <select2 v-model="negatorValue" class="negator" :disabled="readOnly">
      <option value="is">{{ $t('optionIs') }}</option>
      <option value="isnot">{{ $t('optionIsNot') }}</option>
    </select2>

    <select2
      v-model="value.condition"
      :title="$t('selectTitleNothingSelected')"
      class="condition"
      :disabled="readOnly"
    >
      <optgroup :label="$t('optionGroupText')">
        <option :title="$t('optionTextEqualTo')" value="TextEquals">{{ $t('optionEqualTo') }}</option>
        <option :title="$t('optionTextContaining')" value="TextContains">
          {{ $t('optionContaining') }}
        </option>
        <option :title="$t('optionTextBeginningWith')" value="TextBeginsWith">
          {{ $t('optionBeginningWith') }}
        </option>
        <option :title="$t('optionTextEndingWith')" value="TextEndsWith">
          {{ $t('optionEndingWith') }}
        </option>
      </optgroup>
      <optgroup :label="$t('optionGroupNumber')">
        <option :title="$t('optionNumberEquals')" value="NumberEquals">{{ $t('optionEquals') }}</option>
        <option :title="$t('optionNumberLessThan')" value="NumberLessThan">
          {{ $t('optionLessThan') }}
        </option>
        <option :title="$t('optionNumberGreaterThan')" value="NumberGreaterThan">
          {{ $t('optionGreaterThan') }}
        </option>
        <option :title="$t('optionNumberBetween')" value="NumberBetween">{{ $t('optionBetween') }}</option>
      </optgroup>
      <optgroup :label="$t('optionGroupDate')">
        <option :title="$t('optionDateOn')" value="DateOn">{{ $t('optionOn') }}</option>
        <option :title="$t('optionDateBefore')" value="DateBefore">{{ $t('optionBefore') }}</option>
        <option :title="$t('optionDateAfter')" value="DateAfter">{{ $t('optionAfter') }}</option>
        <option :title="$t('optionDateBetween')" value="DateBetween">{{ $t('optionBetween') }}</option>
      </optgroup>
      <optgroup :label="$t('optionGroupField')">
        <option :title="$t('optionFieldEmpty')" value="FieldEmpty">{{ $t('optionEmpty') }}</option>
      </optgroup>
      <optgroup :label="$t('optionGroupBoolean')">
        <option :title="$t('optionBooleanTrue')" value="BooleanYes">{{ $t('optionTrue') }}</option>
        <option :title="$t('optionBooleanFalse')" value="BooleanNo">{{ $t('optionFalse') }}</option>
      </optgroup>
    </select2>

    <div
      :is="conditionCmp"
      v-model="value.conditionArgs"
      class="condition-args"
      v-bind="{ readOnly: readOnly }"
    />
  </div>
</template>
<script>
import ProfileAttributes from '@/components/ProfileAttributes'
import Select2 from '@/components/Select2'

export default {
  components: {
    ProfileAttributes,
    Select2
  },
  props: ['value', 'readOnly'],

  computed: {
    conditionCmp() {
      try {
        return require(`./Profile/${this.value.condition}`).default
      } catch (e) {}

      return null
    },

    negatorValue: {
      get() {
        return this.value.negator ? 'isnot' : 'is'
      },
      set(val) {
        this.value.negator = val !== 'is'
      }
    },

    profileAttributes() {
      return this.$store.state.project.attributes
    }
  },

  created() {
    this.$store.dispatch('fetchAttributes')
  }
}
</script>
<style lang="sass" scoped>

.field
    ::v-deep
        .multiselect__tags
            max-height: 34px
            padding: 6px 30px 6px 12px

            span.multiselect__single
                max-height: 20px
                display: block
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap
                padding: 0

        .multiselect__select
            height: 34px

        .multiselect__select:before /* down arrow */
            border-width: 4px 4px 0
</style>
