<template>
  <div class="webpush-container">
    <TabView>
      <TabPanel>
        <template #header>
          <div class="tab-panel-ct">
            <icon size="15" glyph="apple" />
            <span>MacOS</span>
          </div>
        </template>
        <div class="previews-container">
          <slot name="ios-push" />
        </div>
      </TabPanel>

      <TabPanel>
        <template #header>
          <div class="tab-panel-ct">
            <icon size="15" glyph="windows" />
            <span>Windows</span>
          </div>
        </template>
        <div class="previews-container">
          <slot name="windows-push" />
        </div>
      </TabPanel>

      <TabPanel>
        <template #header>
          <div class="tab-panel-ct">
            <icon size="15" glyph="android" />
            <span>Android</span>
          </div>
        </template>
        <div class="previews-container">
          <slot name="android-push" />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import Tab from '@/components/Tab'
import Tabs from '@/components/TabSet2'

import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

export default {
  components: {
    Tabs,
    Tab,
    TabView,
    TabPanel
  }
}
</script>

<style lang="sass" scoped>
.webpush-container
    margin: 0 90px
    width: 405px

    .tab
        margin-left: -60px

        .previews-container
            display: flex
            flex-direction: column


.tab-panel-ct
  display: flex
  align-items: baseline
  gap: 5px

::v-deep .p-tabview-panels
  display: flex
  justify-content: center
</style>
