<template>
  <div>
    <input
      v-model="amount"
      class="form-control form-number"
      type="number"
      min="0"
      :placeholder="$t('insertNumberPlaceholder')"
      @input="onInput"
      :disabled="readOnly"
    />

    <select2 v-model="operator" class="multiselect field" @input="onInput" :disabled="readOnly">
      <option v-for="(op, value) in operators" :value="value">
        {{ op.title }}
      </option>
    </select2>
  </div>
</template>
<script>
import Select2 from '@/components/Select2'

export default {
  components: { Select2 },
  props: ['value', 'readOnly'],

  data() {
    return {
      operator: this.value.operator,
      amount: this.value.amount || '',
      date: this.value.value || '',
      operators: {
        days: {
          title: this.$t('timeUnitDaysOption')
        },
        hours: {
          title: this.$t('timeUnitHoursOption')
        },
        minutes: {
          title: this.$t('timeUnitMinutesOption')
        }
      }
    }
  },

  mounted() {
    const operators = Object.keys(this.operators)
    this.operator = operators.includes(this.value.operator)
      ? this.value.operator
      : operators[0]
  },

  methods: {
    onInput() {
      this.$emit('input', {
        operator: this.operator,
        amount: this.amount
      })
    }
  }
}
</script>
