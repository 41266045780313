<template>
  <el-dialog
    width="632px"
    :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :modal="true"
  >
    <div class="title" slot="title">
      {{ $t('applyTemplateDialogTitle') }}
    </div>
    <div class="items-container">
      <DataTable
        :selection.sync="selectedTemplate"
        :value="templates"
        :filters.sync="filters"
        filterDisplay="row"
        :scrollable="true"
        scrollHeight="550px"
        stripedRows
        responsiveLayout="scroll"
        paginator :rows="50"
        :loading="isLoading"
        removableSort
      >
        <template #empty>
          {{ $t('applyTemplateDialogNoItems') }}
        </template>
        <template #loading>
          {{ $t('applyTemplateDialogLoading') }}
        </template>
        <Column
          selectionMode="single"
          class="col-toggle"
          :styles="{width: '50px', 'min-width': '50px', 'max-width': '50px'}"
        />
        <Column
          field="title"
          header="Template"
          sortable
          :showFilterMenu="false"
        >
          <template #body="{data}" style="font-weight: 600;">
            {{data.title}}
          </template>
          <template #filter="{filterModel,filterCallback}">
            <InputText
              type="text"
              v-model="filterModel.value"
              @input="filterCallback()"
              class="p-column-filter"
              :placeholder="$t('applyTemplateDialogSearchPlaceholder')"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <div slot="footer" class="dialog-footer">
      <Button
        :disabled="selectedTemplate === null"
        @click="applyTemplate"
        class="p-button-outlined apply-btn"
      >
        <div>{{ $t('applyTemplateDialogApply') }}</div>
        <icon glyph="chevron-right" size="14px" />
      </Button>
    </div>
  </el-dialog>
</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import RadioButton from 'primevue/radiobutton';
import alert from '@/libs/Alert'

export default {
  name: 'ApplyTemplateDialog',
  components: {
    InputText,
    Button,
    DataTable,
    Column,
    RadioButton
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    hasContent: {
      type: Boolean,
      required: false,
      default: false
    },
    contentId: {
      type: Number,
      required: false,
      default: 0
    },
    channel: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedTemplate: null,
      topData: [],
      templates: null,
      isLoading: true,
      filters: {
        title: { value: null, matchMode: 'contains' }
      }
    }
  },

  created() {
    this.$store.dispatch('fetchTemplates', this.channel)
      .then(response => {
        this.templates = response
        this.isLoading = false
      })
  },

  methods: {
    handleClose() {
      this.$emit('setVisible', false)
    },

    applyTemplate() {
      if (
        this.channel === 7 && this.contentId === 0
        || this.channel !== 7 && !this.hasContent
      ) {
        this.doApply()
        return
      }

      alert.show({
        confirm: true,
        type: 'warning',
        okText: this.$t('alertOkText'),
        title: this.$t('templateSectionAlertTitle'),
        message: this.$t('templateSectionAlertMessage'),
        onOk: (resolve) => {
          this.doApply()
          resolve()
        }
      })
    },

    doApply() {
      this.$emit('applyMessageTemplate', this.selectedTemplate)
    }
  }
}
</script>

<style scoped>
.col-toggle {
  width: 50px;
}
.title {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
  float: left
}

.dialog-footer {
  display: flex;
  flex-direction: row-reverse;
}

.apply-btn {
  display: flex;
  color: #2563EB;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  gap: 10px;
  align-items: center;
}

::v-deep .p-datatable-wrapper {
  border: 1px solid #dbdee7;
  border-radius: 3px;
}

::v-deep .p-datatable .p-sortable-column.p-highlight {
  color: #343a40;
  background: #f8f9fa;
}

::v-deep .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #343a40;
}

::v-deep .p-datatable .p-sortable-column:focus,
::v-deep .p-button:focus {
  box-shadow: none;
}

::v-deep .p-datatable .p-paginator-bottom {
  border: 0;
}
</style>
