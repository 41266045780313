<template>
  <div
    class="inbox-preview-message"
    :class="typeName"
    :style="combinedStyles.container"
  >
    <div class="inbox-preview-icon">
      <img :src="icon" />
    </div>
    <div class="inbox-preview-content">
      <div
        class="inbox-preview-title"
        :style="combinedStyles.title"
        v-html="message.title"
      ></div>
      <div
        class="inbox-preview-text"
        :style="combinedStyles.text"
        v-html="text"
      ></div>
      <div class="inbox-preview-date" :style="combinedStyles.date">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import UploadImageIcon from '@/images/website-image.png'

export default {
  name: 'InboxMessagePreview',

  props: {
    message: Object,
    messageType: String,
    messageStyle: Object,
    globalStyles: Object
  },

  computed: {
    typeName() {
      if (this.messageType === 'card') {
        return 'inbox-preview-card'
      }

      return 'inbox-preview-alert'
    },

    icon() {
      if (this.message.icon) {
        return this.message.icon
      }

      return UploadImageIcon
    },

    text() {
      if (this.message.text) {
        return this.message.text
      }

      return this.message.alert
    },

    date() {
      if (this.messageType === 'alert') {
        return moment().format('L LT')
      }
    },

    messageStyles() {
      return {
        container: {
          backgroundColor: this.messageStyle.bg
        },
        title: {
          backgroundColor: this.messageStyle.title_bg
        },
        text: {}
      }
    },

    combinedStyles() {
      let styles = {}
      let globalStyles = this.globalStyles
      let messageStyles = this.messageStyles

      for (let localStyles of [globalStyles, messageStyles]) {
        for (let item of Object.keys(localStyles)) {
          if (styles[item] === undefined) {
            styles[item] = {}
          }
          for (let style of Object.keys(localStyles[item])) {
            if (localStyles[item][style]) {
              styles[item][style] = localStyles[item][style]
            }
          }
        }
      }

      return styles
    }
  }
}
</script>

<style scoped>
.inbox-preview-message {
  border: 1px solid #bebebe;
  background: #ffffff;
  font-family: 'Trebuchet MS', 'Helvetica', sans-serif;
  cursor: default;
  position: relative;
  width: 300px;
}
.inbox-preview-icon {
  display: block;
  line-height: 0;
}

.inbox-preview-alert {
  display: flex;
  padding: 8px;
}
.inbox-preview-alert .inbox-preview-icon {
  margin-right: 8px;
  width: 48px;
}
.inbox-preview-alert .inbox-preview-icon img {
  width: 48px;
  height: 48px;
}
.inbox-preview-alert .inbox-preview-content {
  flex: 1;
  line-break: anywhere;
}
.inbox-preview-alert .inbox-preview-text {
  margin-top: 4px;
  margin-bottom: 4px;
}

.inbox-preview-card .inbox-preview-icon img {
  width: 100%;
}
.inbox-preview-card .inbox-preview-title {
  padding: 5px 7px;
}
.inbox-preview-card .inbox-preview-text,
.inbox-preview-card .inbox-preview-date {
  padding: 3px 7px;
}
.inbox-preview-card .inbox-preview-content {
  line-break: anywhere;
}

.inbox-preview-title,
.inbox-preview-title >>> p {
  font-size: 12px;
  line-height: normal;
  font-family: 'Trebuchet MS', 'Helvetica', sans-serif;
  font-weight: bold;
  margin: 0;
}
.inbox-preview-text,
.inbox-preview-text >>> p {
  font-size: 12px;
  line-height: normal;
  font-family: 'Trebuchet MS', 'Helvetica', sans-serif;
  margin: 0;
}
.inbox-preview-date {
  font-size: 11px;
  line-height: normal;
  color: #b7b7b7;
}
</style>
