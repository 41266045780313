<template>
  <input
    v-model="text"
    class="form-control"
    type="text"
    :placeholder="$t('insertTextPlaceholder')"
    :disabled="readOnly"
  />
</template>
<script>
export default {
  props: ['value', 'readOnly'],

  computed: {
    text: {
      get() {
        return this.value.value || ''
      },

      set(value) {
        this.$emit('input', { value: value })
      }
    }
  }
}
</script>
