<template>
  <div class="group-ct">
    <InputNumber
      :value="retry_for"
      :min="1"
      :max="999"
      :disabled="disabled"
      @input="updateUnitValue"
      show-buttons
      :input-style="{ width: '100px' }"
    />
    <Dropdown
      placeholder="Select Option"
      :options="options"
      :disabled="disabled"
      @change="updateUnitType"
      :value="selectedOption"
      option-label="label"
    />
  </div>
</template>
<script>
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
export default {
  components: {
    InputNumber,
    Dropdown
  },

  props: {
    retry_for: {
      type: Number,
      require: true
    },

    retry_for_period: {
      type: String,
      require: true
    },

    disabled: {
      type: Boolean
    }
  },

  emits: ['retry_for', 'retry_for_period'],

  data() {
    return {
      options: [
        { id: 'seconds', label: this.$t('retryForSeconds') },
        { id: 'minutes', label: this.$t('retryForMinutes') },
        { id: 'hours', label: this.$t('retryForHours') },
        { id: 'days', label: this.$t('retryForDays') },
        { id: 'weeks', label: this.$t('retryForWeeks') }
      ]
    }
  },

  computed: {
    selectedOption: function () {
      return this.options.find(({ id }) => id === this.retry_for_period)
    }
  },

  methods: {
    updateUnitValue(retryForValue) {
      this.$emit('retry_for', retryForValue)
    },

    updateUnitType({ value }) {
      this.$emit('retry_for_period', value.id)
    }
  }
}
</script>
<style scoped>
.group-ct {
  display: flex;
  align-items: center;
  gap: 25px;
}

.form-control {
  height: 38px;
}

.retry-for {
  width: 15%;
  margin-right: 25px;
}

.retry-for-period {
  width: 85%;
}

.select2-container--bootstrap .select2-selection--single {
  height: 38px !important;
}

.select2 .select2-container .select2-container--bootstrap .form-control {
  width: 100% !important;
}
</style>
