<template>
  <div class="phone" :class="{ landscape: orientation === 'landscape' }">
    <template v-if="orientation === 'portrait'">
      <div class="speaker-portrait"></div>
      <div
        class="content-portrait"
        :style="{
          width: width,
          height: height,
          'overflow-y': scrollableContent ? 'auto' : 'visible'
        }"
      >
        <slot />
      </div>
      <div class="button-portrait"></div>
    </template>
    <template v-if="orientation === 'landscape'">
      <div class="button-landscape">
        <div class="button"></div>
      </div>
      <div
        class="content-landscape"
        :style="{
          width: height,
          height: width,
          'overflow-y': scrollableContent ? 'auto' : 'visible'
        }"
      >
        <slot />
      </div>
      <div class="speaker-landscape">
        <div class="speaker"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Phone',

  props: {
    width: {
      required: false,
      default: '325px'
    },
    height: {
      required: false,
      default: '485px'
    },
    orientation: {
      default: 'portrait'
    },
    scrollableContent: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.phone {
  border: 2px solid rgb(118, 118, 118);
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  display: inline-block;
}
.phone.landscape {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}
.speaker-portrait {
  width: 60px;
  height: 6px;
  margin: 20px auto;
  border: 2px solid rgb(118, 118, 118);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.speaker-landscape {
  float: left;
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-column-end: 4;
}
.speaker-landscape .speaker {
  border: 2px solid rgb(118, 118, 118);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 6px;
  height: 64px;
}
.content-portrait {
  width: 320px;
  height: 480px;
  border: 2px solid rgb(118, 118, 118);
  margin: 0 15px;
}
.content-landscape {
  float: left;
  width: 320px;
  height: 480px;
  border: 2px solid rgb(118, 118, 118);
  margin: 15px 0px;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-column-end: 3;
}
.button-portrait {
  height: 36px;
  width: 36px;
  margin: 10px auto;
  border: 2px solid rgb(118, 118, 118);
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
}
.button-landscape {
  width: 60px;
  height: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 2;
}
.button-landscape .button {
  height: 36px;
  width: 36px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  border: 2px solid rgb(118, 118, 118);
}
</style>
