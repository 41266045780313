<template>
  <div>
    <div class="form-group">
      <label class="control-label">{{ $t('promotionLabel') }}:</label>
      <div>
        <el-autocomplete
          :debounce="500"
          :placeholder="placeholder"
          :disabled="readOnly || !loaded"
          v-model="selectedPromotionName"
          :fetch-suggestions="querySearchPromotions"
          @select="onSelect"
        />
      </div>
    </div>

    <div v-if="hasOptions" class="form-group">
      <label class="control-label">{{ $t('optionLabel') }}:</label>
      <multiselect
        :placeholder="$t('selectOptionPlaceholder')"
        :select-label="$t('vueMultiSelectSelectLabel')"
        :selected-label="$t('vueMultiSelectSelectedLabel')"
        :deselect-label="$t('vueMultiSelectDeselectLabel')"
        :deselect-title="$t('vueMultiSelectDeselectTitle')"
        :no-results="$t('vueMultiSelectNoResults')"
        :options="events"
        :value="selectedEvent"
        label="label"
        :disabled="readOnly"
        @input="onInput"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="isAmountRequired" class="form-group">
      <label class="control-label">{{ $t('promotionAmountLabel') }}:</label>
      <div style="margin-bottom: 10px">
        <Dropdown
          v-model="amountType"
          :disabled="readOnly"
          :options="promotionAmountOptions"
          optionLabel="label"
          optionValue="value"
          @input="onAmountTypeChange"
        />
      </div>

      <InputNumber
        v-if="amountType === 'custom'"
        v-model="action.promotion_amount"
        :min="0"
        :max="9999999999"
        :max-fraction-digits="2"
        :disabled="readOnly"
        showButtons
        @blur="onAmountInput"
        :useGrouping="false"
      />
      <multiselect
        v-if="amountType === 'attribute'"
        :placeholder="$t('selectProfileAttributePlaceholder')"
        :select-label="$t('vueMultiSelectSelectLabel')"
        :selected-label="$t('vueMultiSelectSelectedLabel')"
        :deselect-label="$t('vueMultiSelectDeselectLabel')"
        :deselect-title="$t('vueMultiSelectDeselectTitle')"
        :no-results="$t('vueMultiSelectNoResults')"
        :value="selectedNumberProfileAttribute"
        :options="numberProfileAttributes"
        track-by="id"
        label="name"
        :disabled="readOnly"
        @input="onAmountAttributeChange"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="isDurationRequired" class="form-group">
      <label class="control-label">{{ $t('promotionDurationLabel') }}:</label>
      <div style="margin-bottom: 10px">
        <InputNumber
          v-model="action.promotion_duration"
          showButtons
          :disabled="readOnly"
          :min="0"
          :max="2147483647"
          :useGrouping="false"
          @blur="onDurationChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import XpApi from "@/libs/XpApi";

import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'

export default {
  name: 'Promotion',
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  components: {
    Dropdown,
    InputNumber,
    InputText
  },

  data() {
    return {
      translations: {
        alira: this.$t('promotionAliraLabel'),
        lithium: this.$t('promotionLithiumSubscriptionsLabel'),
        lithiumv2: this.$t('promotionLithiumPromotionsLabel'),
        sbtech: this.$t('promotionSBTechLabel'),
        tglab: this.$t('promotionTGLabLabel')
      },
      event: [
        { id: 1, label: this.$t('promotionEventAddToGroupLabel') },
        { id: 2, label: this.$t('promotionEventRemoveFromGroupLabel') }
      ],
      amountType:
        this.action.promotion_amount_attribute_title
          ? 'attribute'
          : 'custom',
      promotion: {},
      selectedPromotionName: '',
      loaded: false,
      promotionAmountOptions: [
        {
          label: this.$t('promotionAmountCustomAmountOption'),
          value: 'custom'
        },
        {
          label: this.$t('promotionAmountProfileAttributeOption'),
          value: 'attribute'
        }
      ]
    }
  },

  computed: {
    placeholder() {
      return this.loaded
          ? this.$t('selectOptionPlaceholder')
          : this.$t('loadingPlaceholder')
    },
    events() {
      let promotion = this.$store.state.project.workflowPromotions.find(
        (promotion) => promotion.id == this.action.promotion_id
      )
      if (!promotion) {
        XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
          params: {
            id: this.action.promotion_id,
            for: 'workflow',
            expand: 'can_add,can_remove,integration_name'
          }
        }).then(({ data }) => {
          if (data) {
            promotion = data[0]
          }
        })
      }
      let event = []
      if (promotion) {
        if (promotion.can_add) {
          event.push({ id: 1, label: this.$t('promotionEventAddToGroupLabel') })
        }
        if (promotion.can_remove) {
          event.push({
            id: 2,
            label: this.$t('promotionEventRemoveFromGroupLabel')
          })
        }
      }
      return event
    },

    selectedEvent() {
      return this.event.find((event) => event.id === this.action.event)
    },

    numberProfileAttributes() {
      return this.$store.state.project.numberProfileAttributes
    },

    selectedNumberProfileAttribute() {
      return this.$store.state.project.numberProfileAttributes.find(
        (attr) => attr.name === this.action.promotion_amount_attribute_title
      )
    },

    hasOptions() {
      let selectedPromotion = this.promotion
      if (selectedPromotion) {
        return !!(selectedPromotion.can_add && selectedPromotion.can_remove)
      }
    },

    isAmountRequired() {
      let selectedPromotion = this.promotion
      if (selectedPromotion) {
        return !!parseInt(selectedPromotion.amount_required)
      }
    },

    isDurationRequired() {
      let selectedPromotion = this.promotion
      if (selectedPromotion) {
        return !!parseInt(selectedPromotion.duration_required)
      }
    }
  },

  created() {
      if (this.action.promotion_id) {
        const foundPromotion =
            this.$store.state.project.workflowPromotions.find((promotion) => promotion.id == this.action.promotion_id)
        if (foundPromotion === undefined) {
          XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
            params: {
              id: this.action.promotion_id,
              for: 'workflow',
              expand: 'can_add,can_remove,integration_name'
            }
          }).then(({ data }) => {
            if (data) {
              this.promotion = data[0]
              this.selectedPromotionName = this.promotionsLabel(data[0])
            }
          })
        } else {
          this.promotion = foundPromotion
          this.selectedPromotionName = this.promotionsLabel(foundPromotion)
        }
      }
    this.$store.dispatch('fetchNumberProfileAttributes')
    this.loaded = true
  },

  methods: {
    promotionsLabel(option) {
      let external_system =
        this.translations[option.external_system] || option.external_system
      if (option.integration) {
        return (
          option.integration_name +
          ' - ' +
          external_system +
          ' - ' +
          option.name
        )
      }
      return external_system + ' - ' + option.name
    },

    onSelect(option) {
      if (!option) {
        return
      }

      this.promotion = option
      this.selectedPromotionName = option ? this.promotionsLabel(option) : ''

      this.action.promotion_amount = 0
      this.action.promotion_duration = 1
      this.action.promotion_amount_attribute_title = ''

      this.workflowApi.updateAction(this.action.id, {
        promotion_amount_required: option.amount_required,
        promotion_duration_required: option.duration_required,
        promotion_id: option.id,
        event: option.can_add || option.can_remove << 1
      })
    },

    onInput(option) {
      if (!option) {
        return
      }
      this.workflowApi.updateAction(this.action.id, { event: option.id })
    },

    onAmountInput(event) {
      const value = Number(event.target.value)

      if (!value) {
        this.action.promotion_amount = 0
        return
      }

      this.action.promotion_amount = value
      this.workflowApi.updateAction(this.action.id, {
        promotion_amount: this.action.promotion_amount
      })
    },

    onAmountAttributeChange(option) {
      if (!option) {
        return
      }

      this.workflowApi.updateAction(this.action.id, {
        promotion_amount_attribute_title: option.name
      })
    },

    onAmountTypeChange() {
      this.workflowApi.updateAction(this.action.id, {
        promotion_amount: 0,
        promotion_amount_attribute_title: null
      })
    },

    onDurationChange(event) {
      const value = event.target.value

      if (!value) {
        this.action.promotion_duration = 1
        return
      }

      this.workflowApi.updateAction(this.action.id, {
        promotion_duration: this.action.promotion_duration
      })
    },

    querySearchPromotions(queryString, cb) {
      XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
        params: {
          name: queryString,
          for: 'workflow',
          expand: 'can_add,can_remove,integration_name'
        }
      }).then(({ data }) => {
        const list = data.map(item => {
          item.value = this.promotionsLabel(item)
          return item
        })
        cb(list)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.el-autocomplete
  width: 100%
  padding-top: 2px

.p-dropdown,
.p-inputnumber
  width: 100%
</style>
