<template>
  <div :key="value.condition" class="criteria-options">
    <profile-attributes
      v-model="value.field"
      :options="profileAttributes"
      :disabled="readOnly"
      class="field"
    />

    <select2
      v-if="!hideNegatorForFields.includes(value.condition)"
      :value="negatorValue"
      class="negator"
      @input="onNegatorChange"
      :disabled="readOnly"
    >
      <option value="is">{{ $t('optionIs') }}</option>
      <option value="isnot">{{ $t('optionIsNot') }}</option>
    </select2>

    <select2
      v-model="value.condition"
      :title="$t('selectTitleNothingSelected')"
      class="condition"
      :disabled="readOnly"
    >
      <optgroup :label="$t('optionGroupText')">
        <option :title="$t('optionTextEqualTo')" value="TextEquals">{{ $t('optionEqualTo') }}</option>
        <option :title="$t('optionTextContaining')" value="TextContains">
          {{ $t('optionContaining') }}
        </option>
        <option :title="$t('optionTextBeginningWith')" value="TextBeginsWith">
          {{ $t('optionBeginningWith') }}
        </option>
        <option :title="$t('optionTextEndingWith')" value="TextEndsWith">
          {{ $t('optionEndingWith') }}
        </option>
      </optgroup>
      <optgroup :label="$t('optionGroupNumber')">
        <option :title="$t('optionNumberEquals')" value="NumberEquals">{{ $t('optionEquals') }}</option>
        <option :title="$t('optionNumberLessThan')" value="NumberLessThan">
          {{ $t('optionLessThan') }}
        </option>
        <option :title="$t('optionNumberGreaterThan')" value="NumberGreaterThan">
          {{ $t('optionGreaterThan') }}
        </option>
        <option :title="$t('optionNumberBetween')" value="NumberBetween">{{ $t('optionBetween') }}</option>
      </optgroup>
      <optgroup :label="$t('optionGroupDate')">
        <option :title="$t('optionDateOn')" value="DateOn">{{ $t('optionOn') }}</option>
        <option :title="$t('optionDateBefore')" value="DateBefore">{{ $t('optionBefore') }}</option>
        <option :title="$t('optionDateAfter')" value="DateAfter">{{ $t('optionAfter') }}</option>
        <option :title="$t('optionDateBetween')" value="DateBetween">{{ $t('optionBetween') }}</option>
        <option :title="$t('optionDateAnniversaryIs')" value="DateAnniversary">
          {{ $t('optionAnniversaryIs') }}
        </option>
        <option :title="$t('optionDateExactly')" value="DateExactly">{{ $t('optionExactly') }}</option>
        <option :title="$t('optionDateInTheLast')" value="DateInTheLast">
          {{ $t('optionInTheLast') }}
        </option>
        <option :title="$t('optionDateInTheNext')" value="DateInTheNext">
          {{ $t('optionInTheNext') }}
        </option>
      </optgroup>
      <optgroup :label="$t('optionGroupField')">
        <option :title="$t('optionFieldEmpty')" value="FieldEmpty">{{ $t('optionEmpty') }}</option>
      </optgroup>
      <optgroup :label="$t('optionGroupBoolean')">
        <option :title="$t('optionBooleanTrue')" value="BooleanYes">{{ $t('optionTrue') }}</option>
        <option :title="$t('optionBooleanFalse')" value="BooleanNo">{{ $t('optionFalse') }}</option>
      </optgroup>
    </select2>

    <div
      :is="conditionCmp"
      v-model="value.conditionArgs"
      class="condition-args"
      v-bind="{ readOnly: readOnly }"
    ></div>
  </div>
</template>
<script>
import ProfileAttributes from '@/components/ProfileAttributes'
import Select2 from '@/components/Select2'

export default {
  components: {
    ProfileAttributes,
    Select2
  },
  props: ['value', 'readOnly'],

  computed: {
    conditionCmp() {
      try {
        return require(`./Profile/${this.value.condition}`).default
      } catch (e) {}

      return null
    },

    negatorValue() {
      return this.value.negator ? 'isnot' : 'is'
    },

    profileAttributes() {
      return this.$store.state.project.profileAttributes
    },

    hideNegatorForFields() {
      return ['DateExactly', 'DateAnniversary']
    }
  },

  watch: {
    'value.condition': {
      immediate: true,
      handler(value) {
        if (this.hideNegatorForFields.includes(value)) {
          // reset negator value
          this.onNegatorChange('is')
        }
      }
    }
  },

  created() {
    this.$store.dispatch('fetchProfileAttributes')
  },

  methods: {
    onNegatorChange(newValue) {
      this.value.negator = newValue !== 'is'
    }
  }
}
</script>
<style lang="sass" scoped>

.field
    ::v-deep
        .multiselect__tags
            max-height: 34px
            padding: 6px 30px 6px 12px

            span.multiselect__single
                max-height: 20px
                display: block
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap
                padding: 0

        .multiselect__select
            height: 34px

        .multiselect__select:before /* down arrow */
            border-width: 4px 4px 0
</style>
