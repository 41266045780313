<template>
  <Dropdown
    :placeholder="placeholder"
    :disabled="disabled"
    @change="onChange"
    v-model="selectedLanguage"
    filter
    :options="options"
    option-label="name"
  />
</template>
<script>
import ISO6391 from 'iso-639-1'

import Dropdown from 'primevue/dropdown'

export default {
  components: {
    Dropdown
  },
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$t('selectALanguageLabel')
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['input'],

  data() {
    return {
      selectedLanguage: null
    }
  },

  mounted() {
    if (Object.keys(this.objectValue).length) {
      this.selectedLanguage = this.objectValue
    }
  },

  computed: {
    objectValue() {
      return this.options.find((option) => option.code === this.value)
    },

    options() {
      return ISO6391.getLanguages(ISO6391.getAllCodes())
    }
  },

  methods: {
    customLabel(option) {
      return option.name
    },

    onChange(option) {
      this.$emit('input', option.value.code)
    }
  }
}
</script>
<style scoped>
.multiselect--active {
  z-index: 4;
}

::v-deep .multiselect__select {
  height: 36px;
}
</style>
