import { render, staticRenderFns } from "./TimePeriod.vue?vue&type=template&id=5c327107&scoped=true"
import script from "./TimePeriod.vue?vue&type=script&lang=js"
export * from "./TimePeriod.vue?vue&type=script&lang=js"
import style0 from "./TimePeriod.vue?vue&type=style&index=0&id=5c327107&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c327107",
  null
  
)

export default component.exports