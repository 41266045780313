<template>
  <div class="toolbox-large-decision">
    <div v-show="!validator.length" style="padding: 10px 0">{{ $t('decisionNoCriteriaLabel') }}</div>

    <div v-for="(group, index) in validator">
      <div class="group">
        <div v-for="(criteria, index) in group" class="criteria form-inline">
          <div style="padding: 15px 10px">
            <select2
              :disabled="readOnly"
              :value="criteriaTypeValue(criteria)"
              class="criteria-type"
              @input="onCriteriaTypeChange(criteria, $event)"
            >
              <option value="profile">{{ $t('decisionCriteriaProfileAttributeOptionLabel') }}</option>
              <option value="message" :disabled="!isMessageOptionAvailable">
                {{ $t('decisionCriteriaMessageOptionLabel') }}
              </option>
              <option value="segment">{{ $t('decisionCriteriaSegmentOptionLabel') }}</option>
              <option value="event">{{ $t('decisionCriteriaEventPropertyOptionLabel') }}</option>
              <option value="date">{{ $t('decisionCriteriaCurrentDateOptionLabel') }}</option>
            </select2>
          </div>

          <component
            :is="criteriaTypeValue(criteria)"
            v-model="group[index]"
            class="criteria-options"
            :features="features"
            :read-only="readOnly"
          />

          <div class="criteria-action">
            <template v-if="index == group.length - 1">
              <span
                v-if="!readOnly"
                v-tooltip:bottom="$t('decisionAddCriteriaLabel')"
                class="criteria-action-button"
                @click="addCriteria(group)"
              >
                <icon size="20" glyph="plus" />
              </span>
            </template>

            <span
              v-if="group.length > 1 && !readOnly"
              v-tooltip:bottom="$t('decisionRemoveCriteriaLabel')"
              class="criteria-action-button criteria-action-remove"
              @click="removeCriteria(criteria, group)"
            >
              <icon size="20" glyph="bin" />
            </span>

            <span
              v-if="group.length == 1 && !readOnly"
              v-tooltip:bottom="$t('decisionRemoveGroupLabel')"
              class="criteria-action-button criteria-action-remove"
              @click="removeGroup(group)"
            >
              <icon glyph="bin" />
            </span>
          </div>

          <div v-show="index < group.length - 1" class="logic logic-criteria">
            {{ criteriaLogicText }}
          </div>
        </div>
      </div>

      <div v-show="index < validator.length - 1" class="logic logic-group">
        {{ groupLogicText }}
      </div>
    </div>
    <Button style="margin-right:18px;" class="logic" @click="addGroup" :disabled="readOnly">
      <icon size="15" glyph="plus-circle" style="margin-right:5px; margin-bottom: 2px"/>
      {{ $t(groupLogicText) }}
    </Button>
    <Button @click="toggleType" :disabled="readOnly">
      <icon style="margin-right:5px; margin-bottom: 2px" size="15" glyph="arrow-right-arrow-left"/>
      {{ $t('decisionSwitchLogicLabel') }}
    </Button>
  </div>
</template>
<script>
import Select2 from '@/components/Select2'
import Profile from './Decision/Profile'
import Message from './Decision/Message'
import Segment from './Decision/Segment'
import Event from './Decision/Event'
import Date from './Decision/Date'
import Button from 'primevue/button'

export default {
  components: {
    Select2,
    Profile,
    Message,
    Segment,
    Event,
    Date,
    Button
  },

  inject: ['workflowApi'],
  props: ['action', 'workflow', 'readOnly'],

  data() {
    let data = {
      validatorType: this.action.validatorType,
      validator: JSON.parse(JSON.stringify(this.action.validator))
    }
    if (data.validator.length === 0) {
      data.validator = [[this.getDefaultProfile()]]
    }
    return data
  },

  computed: {
    groupLogicText() {
      return this.validatorType === 'oneOf' ? this.$t('decisionOrLogicText') : this.$t('decisionAndLogicText')
    },

    criteriaLogicText() {
      return this.validatorType === 'oneOf' ? this.$t('decisionAndLogicText') : this.$t('decisionOrLogicText')
    },

    isOneOf() {
      return this.validatorType === 'oneOf'
    },

    isAllOf() {
      return this.validatorType === 'allOf'
    },

    nonEmailMessages() {
      const nonEmailTypes = ['Push', 'Sms']
      return this.workflow.actions.filter((a) => nonEmailTypes.includes(a.type))
        .length
    },

    isEmailOpenTrackingEnabled() {
      return this.workflow.features.email_open_tracking != undefined
        ? this.workflow.features.email_open_tracking
        : true
    },

    isEmailClickTrackingEnabled() {
      return this.workflow.features.email_click_tracking != undefined
        ? this.workflow.features.email_click_tracking
        : true
    },

    isMessageOptionAvailable() {
      return (
        this.nonEmailMessages > 0 ||
        this.isEmailOpenTrackingEnabled ||
        this.isEmailClickTrackingEnabled
      )
    },

    features() {
      return {
        email_open_tracking: this.isEmailOpenTrackingEnabled,
        email_click_tracking: this.isEmailClickTrackingEnabled
      }
    }
  },

  created() {
    this.initial = JSON.stringify({
      validatorType: this.action.validatorType,
      validator: this.action.validator
    })
  },

  beforeDestroy() {
    let current = JSON.stringify({
      validatorType: this.validatorType,
      validator: this.validator
    })
    if (this.initial !== current) {
      this.workflowApi.updateAction(this.action.id, JSON.parse(current))
    }
  },

  methods: {
    toggleType() {
      if (this.validatorType === 'allOf') {
        this.validatorType = 'oneOf'
      } else {
        this.validatorType = 'allOf'
      }
    },

    criteriaTypeValue(criteria) {
      return criteria.type === 'shared-segment'
        ? 'segment'
        : criteria.type || null
    },

    onCriteriaTypeChange(criteria, selected) {
      if (selected === 'message') {
        Object.assign(criteria, this.getDefaultMessage())
      } else if (selected === 'segment') {
        Object.assign(criteria, this.getDefaultSegment())
      } else if (selected === 'event') {
        Object.assign(criteria, this.getDefaultEvent())
      } else if (selected === 'date') {
        Object.assign(criteria, this.getDefaultDate())
      } else {
        Object.assign(criteria, this.getDefaultProfile())
      }
    },

    getDefaultProfile() {
      return {
        type: 'profile',
        field: '',
        condition: 'TextEquals',
        negator: false,
        conditionArgs: { value: '' }
      }
    },

    getDefaultSegment() {
      return {
        type: 'segment',
        field: '',
        condition: '',
        negator: false,
        conditionArgs: { value: '' }
      }
    },

    getDefaultDate() {
      return {
        type: 'date',
        field: 'first',
        condition: '',
        negator: false,
        conditionArgs: {}
      }
    },

    getDefaultEvent() {
      return {
        type: 'event',
        field: '',
        condition: 'TextEquals',
        negator: false,
        conditionArgs: { value: '' }
      }
    },

    getDefaultMessage() {
      return {
        type: 'message',
        field: 'first',
        condition: 'opened',
        negator: false,
        conditionArgs: {}
      }
    },

    addGroup() {
      this.validator.push([this.getDefaultProfile()])
    },

    addCriteria(group) {
      group.push(this.getDefaultProfile())
    },

    removeGroup(group) {
      let index = this.validator.indexOf(group)
      if (index >= 0) {
        this.validator.splice(index, 1)
      }
    },

    removeCriteria(criteria, fromGroup) {
      let index = fromGroup.indexOf(criteria)
      if (index >= 0) {
        fromGroup.splice(index, 1)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.sidebar-body
  margin-top: 20px

.toolbox-large-decision
    overflow: visible
.group
    margin: 20px 0
    border-radius: 6px
    position: relative
    background-color: $light-blue

.criteria
    font-size: 12px
    position: relative
    display: flex
    flex-wrap: wrap
    border-bottom: 1px solid $panel-default-border

    &:last-child
        border-bottom: none
    &-type
        width: 145px

    &-action
        display: flex
        justify-content: flex-end

        &-button
            font-size: 18px
            margin-top: -3px
            display: flex
            align-items: center
            padding: 0 10px
            cursor: pointer

.logic
    color: $gray
    text-transform: uppercase
    font-weight: bold

    &-criteria
        position: absolute
        top: 64px
        left: 0
        background-color: $light-blue
        width: 65px
        text-align: center

.p-button
  color: #2563EB
  background-color: #FFFFFF
  border-radius: 6px
  border-color: #2563EB
  font-size: 14px
  font-weight: 700

.p-button:focus
  box-shadow: none

::v-deep .form-control
  height: 43px

::v-deep .select2-container--bootstrap .select2-selection--single
  height: 43px
  line-height: 2

::v-deep .p-disabled
  background-color: #E6E6E6
  opacity: 1
  color: #707070
</style>
