<template>
  <div class="time-period">
    <div class="label-input-group">
      <span class="label-new-ui">
        {{ $t('delayToolboxSmallTimePeriodLabel') }}
      </span>
      <time-period
        :value="action.options.interval"
        :disabled="readOnly"
        @input="updateInterval"
      />
    </div>
    <allowed-resume-window-section
      :action="action"
      :read-only="readOnly"
      @saveSafeTime="updateAllowedResumeWindow"
    />
  </div>
</template>
<script>
import TimePeriod from '@/components/SimpleTimePeriod'
import AllowedResumeWindowSection from './AllowedResumeWindowSection'

export default {
  components: {
    AllowedResumeWindowSection,
    TimePeriod
  },
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  methods: {
    saveChanges(changes) {
      this.workflowApi.updateAction(this.action.id, {
        options: Object.assign({}, this.action.options, changes)
      })
    },

    updateInterval(interval) {
      this.saveChanges({
        type: 'timePeriod',
        interval
      })
    },

    updateAllowedResumeWindow(startTime, endTime) {
      this.saveChanges({
        startSafeTime: startTime,
        endSafeTime: endTime
      })
    }
  }
}
</script>
<style scoped>
.time-period {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label-new-ui {
  color: #4b515f;
  font-weight: 600;
}

.label-input-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
</style>
