<template>
  <div class="criteria-options">
    <select2
      class="field"
      :value="segmentValue"
      :options="segmentOptions"
      :placeholder="$t('selectSegmentPlaceholder')"
      :enable-search="true"
      @input="onSegmentChange"
      :disabled="readOnly"
    />
    <select2 :value="negatorValue" class="negator" @input="onNegatorChange" :disabled="readOnly">
      <option value="is">{{ $t('optionIncludesUser') }}</option>
      <option value="isnot">{{ $t('optionExcludesUser') }}</option>
    </select2>
  </div>
</template>
<script>
import ProfileAttributes from '@/components/ProfileAttributes'
import Select2 from '@/components/Select2'

export default {
  components: {
    ProfileAttributes,
    Select2
  },
  props: {
    value: Object,
    readOnly: {
      type: Boolean,
      required: false
    }
  },

  computed: {
    segmentValue() {
      if (this.value.type === 'shared-segment') {
        return 'shared-' + this.value.field
      }
      return this.value.field
    },

    negatorValue() {
      return this.value.negator ? 'isnot' : 'is'
    },

    segmentOptions() {
      const segments = this.segments
      const sharedSegments = this.sharedSegments

      // Add options for values that no longer appear in the rest response
      if (
        this.value.type === 'segment' &&
        !segments.find((segment) => segment.id === parseInt(this.value.field))
      ) {
        segments.unshift({ id: this.value.field, text: 'Deleted Segment' })
      } else if (
        this.value.type === 'shared-segment' &&
        !sharedSegments.find(
          (segment) => segment.id === 'shared-' + this.value.field
        )
      ) {
        sharedSegments.unshift({
          id: 'shared-' + this.value.field,
          text: 'Deleted Shared Segment'
        })
      }

      if (sharedSegments.length) {
        return [
          { text: 'Segments', children: segments },
          { text: 'Shared Segments', children: sharedSegments }
        ]
      }

      return segments
    },

    segments() {
      return this.$store.state.project.segments.map((segment) => ({
        id: segment.id,
        text: segment.title
      }))
    },

    sharedSegments() {
      return this.$store.state.project.sharedSegments.map((segment) => ({
        id: 'shared-' + segment.id,
        text: segment.project_title + ': ' + segment.title
      }))
    }
  },

  created() {
    this.value.condition = 'IncludesProfile'
    this.$store.dispatch('fetchSegments')
    this.$store.dispatch('fetchSharedSegments')
  },

  methods: {
    onSegmentChange(newValue) {
      if (newValue.startsWith('shared-')) {
        this.value.type = 'shared-segment'
        this.value.field = newValue.replace('shared-', '')
        return
      }

      this.value.type = 'segment'
      this.value.field = newValue
    },

    onNegatorChange(newValue) {
      this.value.negator = newValue !== 'is'
    }
  }
}
</script>
<style lang="sass" scoped>

.criteria-options
    .negator
        width: 150px

    .field
        width: 300px
</style>
