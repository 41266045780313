var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar sidebar-lg"},[_c('div',{staticClass:"sidebar-header action-style-workflowreporting"},[_c('action-icon',{staticClass:"sidebar-icon",attrs:{"action-type":"WorkflowReporting","size":"38"}}),_vm._v(" "),_c('div',{staticClass:"sidebar-title"},[_vm._v(_vm._s(_vm.$t('workflowReporting')))]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":_vm.$t('closeButton')},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])],1),_vm._v(" "),_c('div',{staticClass:"sidebar-body"},[_c('TabView',{attrs:{"active-index":_vm.activeTabIndex},on:{"update:activeIndex":function($event){_vm.activeTabIndex=$event},"update:active-index":function($event){_vm.activeTabIndex=$event}}},[_c('TabPanel',{attrs:{"header":"Workflow"}},[_c('workflow-started',_vm._b({},'workflow-started',{
            startedTotal: _vm.startedTotal,
            completedTotal: _vm.completedTotal,
            dateRange: _vm.dateRange,
            startedStats: _vm.startedStats,
            completedStats: _vm.completedStats
          },false)),_vm._v(" "),_c('workflow-stats',_vm._b({},'workflow-stats',{
            startedTotal: _vm.startedTotal,
            completedTotal: _vm.completedTotal,
            waitingTotal: _vm.waitingTotal,
            emailArrivedTotal: _vm.emailArrivedTotal,
            emailSentTotal: _vm.emailSentTotal,
            emailDeliveredTotal: _vm.emailDeliveredTotal,
            emailBouncesTotal: _vm.emailBouncesTotal,
            emailUniqueOpensTotal: _vm.emailUniqueOpensTotal,
            emailUniqueClicksTotal: _vm.emailUniqueClicksTotal,
            emailUnsubscribesTotal: _vm.emailUnsubscribesTotal,
            smsArrivedTotal: _vm.smsArrivedTotal,
            smsSentTotal: _vm.smsSentTotal,
            pushArrivedTotal: _vm.pushArrivedTotal,
            pushSentTotal: _vm.pushSentTotal,
            pushDeliveredTotal: _vm.pushDeliveredTotal,
            pushClickedTotal: _vm.pushClickedTotal,
            channels: _vm.channels,
            workflow: _vm.workflow
          },false))],1),_vm._v(" "),(_vm.channels.push)?_c('TabPanel',{attrs:{"header":"Push"}},[_c('push-stats',_vm._b({},'push-stats',{
            dateRange: _vm.dateRange,
            pushArrivedTotal: _vm.pushArrivedTotal,
            pushSentTotal: _vm.pushSentTotal,
            pushDeliveredTotal: _vm.pushDeliveredTotal,
            pushClickedTotal: _vm.pushClickedTotal,
            pushSentStats: _vm.pushSentStats,
            pushClickedStats: _vm.pushClickedStats
          },false))],1):_vm._e(),_vm._v(" "),(_vm.channels.email)?_c('TabPanel',{attrs:{"header":"Email"}},[_c('email-delivery',_vm._b({},'email-delivery',{
            emailArrivedTotal: _vm.emailArrivedTotal,
            emailSentTotal: _vm.emailSentTotal,
            emailDeliveredTotal: _vm.emailDeliveredTotal,
            emailBouncesTotal: _vm.emailBouncesTotal,
            dateRange: _vm.dateRange,
            emailSentStats: _vm.emailSentStats
          },false)),_vm._v(" "),_c('email-engagement',_vm._b({},'email-engagement',{
            emailDeliveredTotal: _vm.emailDeliveredTotal,
            emailUniqueOpensTotal: _vm.emailUniqueOpensTotal,
            emailUniqueClicksTotal: _vm.emailUniqueClicksTotal,
            emailUnsubscribesTotal: _vm.emailUnsubscribesTotal,
            dateRange: _vm.dateRange,
            emailSentStats: _vm.emailSentStats,
            emailUniqueOpensStats: _vm.emailUniqueOpensStats,
            emailUniqueClicksStats: _vm.emailUniqueClicksStats,
            emailUnsubscribesStats: _vm.emailUnsubscribesStats,
            workflow: _vm.workflow
          },false))],1):_vm._e(),_vm._v(" "),(_vm.channels.sms)?_c('TabPanel',{attrs:{"header":"SMS"}},[_c('sms-delivery',_vm._b({},'sms-delivery',{
            smsArrivedTotal: _vm.smsArrivedTotal,
            smsSentTotal: _vm.smsSentTotal,
            dateRange: _vm.dateRange,
            smsSentStats: _vm.smsSentStats,
            smsUnsubscribesStats: _vm.smsUnsubscribesStats
          },false))],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }