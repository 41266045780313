<template>
  <div
    class="device"
    :class="[type, { landscape: orientation === 'landscape' }]"
  >
    <template v-if="orientation === 'portrait'">
      <div class="content-portrait" :style="{ width: width, height: height }">
        <slot />
      </div>
    </template>
    <template v-if="orientation === 'landscape'">
      <div class="content-landscape" :style="{ width: height, height: width }">
        <slot />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PhoneNew',

  props: {
    width: {
      required: false,
      default: '254px'
    },
    height: {
      required: false,
      default: '550px'
    },
    type: {
      required: false,
      default: 'phone'
    },
    orientation: {
      default: 'portrait'
    }
  }
}
</script>

<style scoped lang="scss">
.device {
  overflow: auto;
  position: relative;
  margin: 0 auto;
  width: fit-content;
}
.device.landscape {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}
.content-portrait {
  width: 254px;
  height: 550px;
}
.content-landscape {
  float: left;
  width: 254px;
  height: 550px;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-column-end: 3;
}

.tablet {
  border-radius: 21px;
  box-shadow: 0 0 0 8px #3e3e3e, 0 0 0 11px #545454, 0 0 0 13px #272727d1;
}

.phone {
  border-radius: 23px;
  box-shadow: 0 0 0 8px #3e3e3e, 0 0 0 10px #545454, 0 0 0 15px #272727d1;
}
</style>
