<template>
  <div class="safeTimeSection">
    <div class="safeTimeLabel">
      <span class="label-new-ui">
        {{ $t('delayToolboxSmallAllowedResumeWindowSectionLabel') }}
      </span>
    </div>
    <div class="safeTimeWrapper">
      <div ref="startSafeTime" class="input-group date startSafeTime">
        <input
          type="text"
          class="form-control"
          :disabled="readOnly"
          :placeholder="$t('allowedResumeWindowStartTime')"
        />
        <span class="input-group-addon">
          <icon glyph="clock" />
        </span>
      </div>
    </div>
    <div class="safeTimeWrapper">
      <div ref="endSafeTime" class="input-group date endSafeTime">
        <input
          type="text"
          class="form-control"
          :disabled="readOnly"
          :placeholder="$t('allowedResumeWindowEndTime')"
        />
        <span class="input-group-addon">
          <icon glyph="clock" />
        </span>
      </div>
    </div>
    <span class="help-block">
      {{ $t('delayToolboxSmallAllowedResumeWindowSectionHelpBlock') }}
    </span>
  </div>
</template>

<script>
import $ from 'jquery'
import '@/libs/bootstrap-datepicker/bootstrap-datetimepicker'

export default {
  props: ['action', 'readOnly'],
emits: ['saveSafeTime'],

  watch: {
    'action.options.startSafeTime': 'updateTimePickers',
    'action.options.endSafeTime': 'updateTimePickers'
  },

  mounted() {
    $(this.$refs.startSafeTime)
      .add(this.$refs.endSafeTime)
      .datetimepicker({
        format: 'h:mm A',
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'bottom'
        }
      })
      .on('dp.hide', this.saveOptions)

    this.startSafeTime = $(this.$refs.startSafeTime).data('DateTimePicker')
    this.endSafeTime = $(this.$refs.endSafeTime).data('DateTimePicker')

    this.updateTimePickers()
  },

  methods: {
    updateTimePickers() {
      this.preventSave = true

      if (this.action.options.startSafeTime) {
        this.startSafeTime.date(this.action.options.startSafeTime)
      }

      if (this.action.options.endSafeTime) {
        this.endSafeTime.date(this.action.options.endSafeTime)
      }

      this.preventSave = false
    },

    saveOptions() {
      if (this.preventSave) return

      const startTime = this.startSafeTime.date()
      const endTime = this.endSafeTime.date()

      this.$emit(
        'saveSafeTime',
        startTime ? startTime.format('h:mm A') : '',
        endTime ? endTime.format('h:mm A') : ''
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.safeTimeSection

  > .safeTimeWrapper
    width: 49%
    display: inline-block

    &:last-child
      float: right

    input.form-control
      z-index: auto

  .input-group-addon
    background-color: #ffffff

  .safeTimeLabel
    margin-bottom: 7px

  .label-new-ui
    color: #4b515f
    font-weight: 600
</style>
