<template>
  <div class="form-group">
    <label class="control-label">{{ $t('splitSmallToolboxLabel') }}</label>
    <Dropdown
      v-model="successorCount"
      class="select-field"
      :disabled="readOnly"
      @change="updateSuccessors"
      :options="options"
      optionLabel="label"
      optionValue="value"
    />
  </div>
</template>
<script>
import Dropdown from 'primevue/dropdown'

export default {
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],
  components: { Dropdown },

  data() {
    return {
      successorCount: this.getSuccessorCount(),
      options: [
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 }
      ],
    }
  },

  watch: {
    'action.successors': {
      handler() {
        this.successorCount = this.getSuccessorCount()
      }
    }
  },

  methods: {
    updateSuccessors() {
      let key = 'a'
      const map = {}

      for (let i = 0; i < Math.min(this.successorCount, 5); i++) {
        map[key] = this.action.successors[key] || ''
        key = String.fromCharCode(key.charCodeAt(0) + 1)
      }

      this.workflowApi.updateAction(this.action.id, { successors: map })
    },

    getSuccessorCount() {
      return Object.keys(this.action.successors).length
    }
  }
}
</script>
<style scoped>
.select-field {
  width: 60px;
}
</style>
