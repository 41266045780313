<template>
  <div>
    <div class="label-switch-group">
      <span class="new-ui-label" @click="updateCheck(!value.check)">
        {{ $t('nationalExclusionSettingsCheck') }}
      </span>
      <InputSwitch :disabled="disabled" v-model="value.check" />
    </div>
    <div v-if="value.check">
      <div class="category-selection">
        <select2
          style="width: 100%"
          :disabled="disabled"
          :value="value.category"
          :placeholder="$t('nationalExclusionSettingsSelectCategory')"
          @input="updateCategory($event)"
        >
          <template v-if="isOasisAvailable && isBetssonAvailable">
            <optgroup
              :label="group.title"
              :key="group.title"
              v-for="group in groupedOptions"
            >
              <option
                :value="option.value"
                v-for="option in group.options"
                :title="option.titleSelected"
              >
                {{ option.title }}
              </option>
            </optgroup>
          </template>

          <template v-else-if="isBetssonAvailable">
            <option
              :value="option.value"
              v-for="option in betssonOptions"
              :key="option.value"
            >
              {{ option.title }}
            </option>
          </template>
          <template v-else-if="isOasisAvailable">
            <option
              :value="option.value"
              v-for="option in oasisOptions"
              :key="option.value"
            >
              {{ option.title }}
            </option>
          </template>
        </select2>
      </div>
    </div>
  </div>
</template>

<script>
import NationalExclusionCategoriesMixin from '@/views/WorkflowEdit/NationalExclusionCategoriesMixin'
import InputSwitch from 'primevue/inputswitch'

export default {
  name: 'NationalExclusionCheckSettings',
  components: { InputSwitch },
  mixins: [NationalExclusionCategoriesMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    updateCheck(value) {
      if (this.disabled) {
        return
      }

      this.value.check = value
    },

    updateCategory(value) {
      this.value.category = value
    }
  },
  computed: {
    options() {
      if (this.isBetssonAvailable) {
        return this.betssonOptions
      }
      if (this.isOasisAvailable) {
        return this.oasisOptions
      }
      return []
    },
    groupedOptions() {
      return [
        {
          title: this.$t('nationalExclusionTechssonPlayerCheck'),
          options: this.betssonOptions
        },
        {
          title: this.$t('nationalExclusionOasisPlayerCheck'),
          options: this.oasisOptions
        }
      ]
    }
  },

  watch: {
    'value.check': {
      deep: true,
      handler(checkValue) {
        if (this.disabled) {
          return
        }

        if (!checkValue) {
          this.value.category = null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.category-selection {
  width: 100%;

  ::v-deep select {
    width: 50%;
  }
}

::v-deep .vue-js-switch {
  z-index: 0;
}

.new-ui-label {
  color: #4b515f;
  font-weight: 600;
  width: 200px;
}

.label-switch-group {
  display: flex;
  align-items: center;
  gap: 100px;
}
</style>
