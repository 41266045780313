<template>
  <input
    v-model="url"
    class="form-control"
    type="text"
    :placeholder="$t('insertUrlPlaceholder')"
    @input="$emit('input', { value: url })"
    :disabled="readOnly"
  />
</template>
<script>
export default {
  props: ['value', 'readOnly'],

  data() {
    return {
      url: this.value.value || ''
    }
  }
}
</script>
