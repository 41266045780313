<template>
  <div
    class="side-nav-icon-ct"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <icon :glyph="actionIcon" size="38" :custom-svg-styles="customSvgStyles" />
  </div>
</template>

<script>
import { ActionIcons } from '@/enums/ActionIconEnum'
export default {
  name: 'SideNavIcon',

  props: {
    action: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      ActionIcons,
      hover: false
    }
  },

  computed: {
    actionIcon() {
      return ActionIcons[this.action.type].icon
    },

    customSvgStyles() {
      return {
        color: ActionIcons[this.action.type].color,
        fill: ActionIcons[this.action.type].fill,
        boxShadow: this.hover
          ? `0px 0px 14px 0px ${ActionIcons[this.action.type].color}`
          : '',
        borderRadius: '6px'
      }
    }
  }
}
</script>

<style scoped>
.side-nav-icon-ct {
  cursor: grab;
}
</style>
