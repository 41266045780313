<template>
  <div
    class="card"
    :class="{ card__with_picture_on_hover: showPictureOnHover }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div class="card__content" :class="{ 'with-icon': renderedIcon !== null }">
      <div class="card__info">
        <i :class="renderedBrowser.icon" class="card__icon--small" />
        <div class="card__infobar" :class="extraSpacing">
          <span class="card__infobar__name">{{ renderedBrowser.name }}</span>
          <span class="card__infobar__separator--1"></span>
          <span class="card__infobar__domain">
            {{ $t('devicePreviewTextExampleWebsite') }}
          </span>
          <span class="card__infobar__separator--2"></span>
          <span class="card__infobar__timestamp">
            {{ $t('devicePreviewTextNow').toLowerCase() }}
          </span>
          <i
            :class="{
              'card__infobar__arrow-down': !showPictureOnHover || !hover,
              'card__infobar__arrow-up': showPictureOnHover && hover
            }"
          ></i>
        </div>
      </div>
      <div class="card__title">
        {{ renderedTitle }}
      </div>
      <div class="card__text" style="white-space: pre-wrap;">
        <span>{{ renderedMessage }}</span>
      </div>
      <div v-if="showPictureOnHover && renderedPicture" class="card__picture">
        <img :src="renderedPicture" />
      </div>
      <div v-if="hover" class="actions">
        <div v-for="action in actions" class="action">{{ action }}</div>
      </div>
    </div>
    <div class="card__icon--large" v-if="renderedIcon !== null">
      <img class="notification-box__card-message__icon" :src="icon" />
    </div>
  </div>
</template>

<script>
import ChromeIcon from '@/images/chrome.png'
import FirefoxIcon from '@/images/firefox.png'

export default {
  name: 'PushPreviewWebAndroid',
  props: {
    browser: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    message: {
      type: String
    },
    icon: {
      type: String
    },
    picture: {
      type: String
    },
    showPictureOnHover: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    renderedTitle() {
      if (this.title === '' || this.title === null) {
        return this.$t('devicePreviewTextNotificationPreview')
      } else {
        return this.title
      }
    },
    renderedMessage() {
      if (this.message === '' || this.message === null) {
        return this.$t('devicePreviewTextNotificationPreviewMessage')
      } else {
        return this.message
      }
    },
    renderedIcon() {
      if (this.icon === '' || this.icon === null) {
        return null
      } else {
        return this.icon
      }
    },
    renderedBrowser() {
      switch (this.browser) {
        case 'chrome':
          return {
            name: this.$t('devicePreviewChrome'),
            img: ChromeIcon,
            icon: 'iconp-chrome'
          }
        case 'firefox':
          return {
            name: this.$t('devicePreviewFirefox'),
            img: FirefoxIcon,
            icon: 'iconp-firefox'
          }
        default:
          return {
            name: this.$t('devicePreviewNotDefined'),
            img: '',
            icon: ''
          }
      }
    },
    renderedPicture() {
      if (this.picture === '' || this.picture === null) return null

      return this.picture
    },
    extraSpacing() {
      return {
        'extra-spacing': this.renderedBrowser.icon === ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
.card {
  display: flex;
  flex-direction: row;
  width: 352px;
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  position: relative;
  padding: 14px;

  &__icon--small {
    font-size: 16px;
    height: 16px;
    display: flex;
  }

  &__icon--large {
    width: 34px;
    height: 34px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    & > img {
      width: 34px;
      min-width: 34px;
      max-width: 34px;
      min-height: 34px;
      max-height: 34px;
      height: 34px;
      background-color: rgb(254, 254, 254);
    }
  }

  &__content {
    max-width: 100%;
    flex: 5;
    display: flex;
    flex-direction: column;
    &.with-icon {
      max-width: 83%;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  &__infobar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 165px;
    height: 13px;

    &__name {
      position: static;
      height: 13px;
      left: 0%;
      right: 76.36%;
      top: calc(50% - 13px / 2);
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: #212121;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 4px;
    }

    &__domain {
      position: static;
      height: 13px;
      left: 30.3%;
      right: 25.45%;
      top: calc(50% - 13px / 2);
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: #212121;
      flex: none;
      order: 2;
      flex-grow: 0;
      margin: 0px 4px;
    }

    &__timestamp {
      position: static;
      height: 13px;
      left: 81.21%;
      right: 6.06%;
      top: calc(50% - 13px / 2);

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: #212121;
      flex: none;
      order: 4;
      flex-grow: 0;
      margin: 0px 4px;
    }

    &__separator--1 {
      position: static;
      width: 3px;
      height: 3px;
      left: 43px;
      top: 5px;
      background: #212121;
      border-radius: 50%;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 4px;
    }

    &__separator--2 {
      position: static;
      width: 3px;
      height: 3px;
      left: 127px;
      top: 5px;
      background: #212121;
      border-radius: 50%;
      flex: none;
      order: 3;
      flex-grow: 0;
      margin: 0px 4px;
    }

    &__arrow-down {
      border: solid black;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 2px;
      vertical-align: middle;
      margin-top: -2px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      flex: none;
      order: 5;
      flex-grow: 0;
      margin-left: 4px;
    }

    &__arrow-up {
      border: solid black;
      border-width: 1px 0 0 1px;
      display: inline-block;
      padding: 2px;
      vertical-align: middle;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      flex: none;
      order: 5;
      flex-grow: 0;
      margin-left: 4px;
    }
  }

  &__title {
    height: 18px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #212121;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text {
    max-height: 45px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #212121;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__picture {
    margin: 10px;
    text-align: center;
    display: none;
    align-items: center;
    align-content: center;
    justify-content: center;

    img {
      max-height: 220px;
      max-width: 100%;
    }
  }

  .extra-spacing {
    left: 14px;
  }

  &.card__with_picture_on_hover:hover {
    .card__picture {
      display: flex;
    }
  }

  .actions {
    background-color: #eeeeee;
    margin-top: 10px;
    padding: 0 4px;
    display: flex;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .actions .action {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    color: #757575;
    font-size: 12px;
  }
}
</style>
