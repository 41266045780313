<template>
  <div class="main-ct">
    <div class="label-input-group">
      <span class="label-new-ui">
        {{ $t('delayToolboxSmallProfileAttributeLabel') }}
      </span>
      <profile-attributes
        :value="action.options.profileAttribute"
        :options="profileAttributes"
        :disabled="readOnly"
        @input="updateProfileAttribute"
      />
    </div>
    <allowed-resume-window-section
      :action="action"
      :read-only="readOnly"
      @saveSafeTime="updateAllowedResumeWindow"
    />
  </div>
</template>
<script>
import ProfileAttributes from '@/components/ProfileAttributes'
import AllowedResumeWindowSection from './AllowedResumeWindowSection'

export default {
  components: {
    AllowedResumeWindowSection,
    ProfileAttributes
  },
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  computed: {
    profileAttributes() {
      return this.$store.state.project.attributes
    }
  },

  methods: {
    saveChanges(changes) {
      this.workflowApi.updateAction(this.action.id, {
        options: Object.assign({}, this.action.options, changes)
      })
    },

    updateProfileAttribute(profileAttribute) {
      this.saveChanges({
        type: 'profileAttribute',
        profileAttribute
      })
    },

    updateAllowedResumeWindow(startTime, endTime) {
      this.saveChanges({
        startSafeTime: startTime,
        endSafeTime: endTime
      })
    }
  }
}
</script>
<style scoped>
.main-ct {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label-input-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.label-new-ui {
  color: #4b515f;
  font-weight: 600;
}
</style>
