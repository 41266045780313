<template>
  <div class="section">
    <div class="container">
      <component :is="phoneFrame" :scrollable-content="isContentScrollable">
        <lock-screen v-if="platform === 'ios'" :background="newFrame">
          <push-preview-ios
            :message="formattedMessage"
            :app-icon="appIcon"
            :app-name="appName"
            @contentExpanded="isContentScrollable = !isContentScrollable"
          ></push-preview-ios>
        </lock-screen>
        <lock-screen-android
          v-if="platform === 'android'"
          :background="newFrame"
        >
          <push-preview-android
            :message="formattedMessage"
            :app-icon="appIcon"
            :app-name="appName"
            @contentExpanded="isContentScrollable = !isContentScrollable"
          ></push-preview-android>
        </lock-screen-android>
      </component>
    </div>
  </div>
</template>

<script>
import PushPreviewIos from '@/components/DevicePreview/PushPreviewIos'
import PushPreviewAndroid from '@/components/DevicePreview/PushPreviewAndroid'
import Phone from '@/components/Phone/Phone'
import PhoneNew from '@/components/Phone/PhoneNew'
import LockScreen from '@/components/Phone/LockScreen'
import LockScreenAndroid from '@/components/Phone/LockScreenAndroid'

export default {
  name: 'PushPreviewSection',

  components: {
    LockScreenAndroid,
    Phone,
    PhoneNew,
    LockScreen,
    PushPreviewIos,
    PushPreviewAndroid
  },
  props: {
    platform: String,
    appIcon: String,
    appName: String,
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    actions: {
      type: Array,
      required: false
    },
    newFrame: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message: {},
      isContentScrollable: false
    }
  },

  computed: {
    phoneFrame() {
      return this.newFrame ? 'PhoneNew' : 'Phone'
    },
    formattedMessage() {
      return Object.assign(
        {
          title: this.title || '',
          text: this.text || '',
          picture: this.icon || '',
          actions: this.actions || []
        },
        this.message
      )
    }
  }
}
</script>

<style scoped>
.container {
  text-align: center;
  width: unset;
  height: 575px;
  margin-top: 15px;
}
</style>
