<template>
  <div class="sms-message-ct">
    <div class="sms-message-ct">
      <div class="label-input-group">
        <span class="new-ui-label-no-width">{{ $t('fromNameLabel') }}</span>
        <personalised-input
          id="fromName"
          v-model="value.fromName"
          type="text"
          :emoji-enabled="false"
          :placeholder="$t('fromNameLabel')"
          style="width: 100%"
          :disabled="disabled"
        />
      </div>
      <div>
        <div class="label-input-group">
          <span class="new-ui-label-no-width">{{ $t('messageLabel') }}</span>
          <personalised-input
            id="message"
            v-model="value.text"
            type="textarea"
            :profile-attributes="profileAttributes"
            rows="6"
            :placeholder="$t('yourMessageTextLabel')"
            :disabled="disabled"
          />
        </div>
        <sms-counter
          :content="smsCounter"
          :shorten-url="shortenUrl"
          :force-estimated-label="withVoucherCodes"
          class="pull-right"
          style="margin-right: 25px"
        />
      </div>
    </div>
    <div>
      <div v-if="optOutReplyEnabled">
        <span class="new-ui-label-no-width">Opt-out Phrase</span>
        <sms-opt-out-input v-model="optOutMessage"></sms-opt-out-input>
      </div>
    </div>
  </div>
</template>

<script>
import ISO6391 from 'iso-639-1'
import PersonalisedInput from '@/components/PersonalisedInput'
import SmsCounter from '@/components/SmsCounter'
import SmsOptOutInput from '@/components/SmsOptOutInput'

export default {
  components: {
    PersonalisedInput,
    SmsCounter,
    SmsOptOutInput
  },

  props: [
    'value',
    'isDefault',
    'lang',
    'profileAttributes',
    'disabled',
    'unsubscribeUrl',
    'optOutReplyEnabled',
    'optOutMessage',
    'shortenUrl',
    'withVoucherCodes'
  ],

  computed: {
    smsCounter() {
      let text = this.value.text

      if (this.withVoucherCodes) {
        text += ' X6RgzCXb'
      }

      if (this.optOutReplyEnabled) {
        return text + this.optOutMessage
      }
      return text + this.unsubscribeUrl
    },
    getLanguage() {
      return ISO6391.getName(this.lang)
    }
  }
}
</script>
<style scoped>
.sms-message-ct {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-ui-label-no-width {
  color: #4b515f;
  font-weight: 600;
}

.label-input-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
</style>
