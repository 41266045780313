var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone",class:{ landscape: _vm.orientation === 'landscape' }},[(_vm.orientation === 'portrait')?[_c('div',{staticClass:"speaker-portrait"}),_vm._v(" "),_c('div',{staticClass:"content-portrait",style:({
        width: _vm.width,
        height: _vm.height,
        'overflow-y': _vm.scrollableContent ? 'auto' : 'visible'
      })},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"button-portrait"})]:_vm._e(),_vm._v(" "),(_vm.orientation === 'landscape')?[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"content-landscape",style:({
        width: _vm.height,
        height: _vm.width,
        'overflow-y': _vm.scrollableContent ? 'auto' : 'visible'
      })},[_vm._t("default")],2),_vm._v(" "),_vm._m(1)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-landscape"},[_c('div',{staticClass:"button"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"speaker-landscape"},[_c('div',{staticClass:"speaker"})])
}]

export { render, staticRenderFns }