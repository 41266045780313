<template>
  <div class="device-message-ct">
    <div class="label-input-group">
      <span class="new-ui-label">{{ $t('messageTitleLabel') }}</span>
      <personalised-input
        v-model="message.title"
        type="text"
        :profile-attributes="profileAttributes"
        :placeholder="$t('deviceMessageTitlePlaceholder')"
        @input="onChange"
        :disabled="disabled"
      />
    </div>
    <div class="label-input-group">
      <span class="new-ui-label">{{ $t('messageTextLabel') }}</span>
      <personalised-input
        v-model="message.text"
        type="textarea"
        :profile-attributes="profileAttributes"
        rows="5"
        :placeholder="$t('yourMessageTextLabel')"
        @input="onChange"
        :disabled="disabled"
      />
    </div>
  </div>
</template>
<script>
import ISO6391 from 'iso-639-1'
import PersonalisedInput from '@/components/PersonalisedInput'

export default {
  components: {
    PersonalisedInput
  },

  props: ['isDefault', 'lang', 'value', 'profileAttributes', 'disabled'],

  data() {
    const message = JSON.parse(JSON.stringify(this.value))
    return {
      message
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        Object.assign(this.message, JSON.parse(JSON.stringify(this.value)))
      }
    }
  },

  methods: {
    onChange() {
      this.$emit('input', JSON.parse(JSON.stringify(this.message)))
    }
  },

  computed: {
    getLanguage() {
      return ISO6391.getName(this.lang)
    }
  }
}
</script>
<style scoped>
.device-message-ct {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-ui-label {
  color: #4b515f;
  font-weight: 600;
}

.label-input-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
</style>
