<template>
  <div class="main-ct">
    <div class="date-settings-ct">
      <div class="label-input-group">
        <span class="label-new-ui">
          {{ $t('delayToolboxSmallRelativeDateLabel') }}
        </span>
        <div>
          <time-period
            :value="action.options.interval"
            :disabled="readOnly"
            @input="updateInterval"
          />
        </div>
      </div>
      <div>
        <Dropdown
          style="width: 100%"
          :value="action.options.beforeOrAfter"
          :options="beforeAfterOptions"
          option-label="label"
          option-value="value"
          :disabled="readOnly"
          @change="updateBeforeAfter"
        />
      </div>
      <div>
        <Dropdown
          style="width: 100%"
          :value="action.options.relativeTo || 'profileAttribute'"
          :options="relativeToOptions"
          :disabled="readOnly"
          option-label="label"
          option-value="value"
          @change="updateRelativeTo"
        />
      </div>
      <div v-if="action.options.relativeTo === 'eventAttribute'">
        <InputText
          style="width: 100%"
          :value="action.options.eventAttribute"
          :disabled="readOnly"
          :placeholder="
            $t('delayToolboxSmallRelativeDateEventPropertyInputPlaceholder')
          "
          @change="updateEventAttribute"
        />
      </div>
      <div v-else>
        <profile-attributes
          style="width: 100%"
          :value="action.options.profileAttribute"
          :options="profileAttributes"
          :disabled="readOnly"
          @input="updateProfileAttribute"
        />
      </div>
    </div>
    <allowed-resume-window-section
      :action="action"
      :read-only="readOnly"
      @saveSafeTime="updateAllowedResumeWindow"
    />
  </div>
</template>
<script>
import TimePeriod from '@/components/SimpleTimePeriod'
import ProfileAttributes from '@/components/ProfileAttributes'
import AllowedResumeWindowSection from './AllowedResumeWindowSection'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'

export default {
  components: {
    AllowedResumeWindowSection,
    TimePeriod,
    ProfileAttributes,
    Dropdown,
    InputText
  },

  inject: ['workflowApi'],

  props: ['action', 'readOnly'],

  data() {
    return {
      beforeAfterOptions: [
        {
          value: 'before',
          label: this.$t('delayToolboxSmallRelativeDateSelectOptionBefore')
        },
        {
          value: 'after',
          label: this.$t('delayToolboxSmallRelativeDateSelectOptionAfter')
        }
      ],
      relativeToOptions: [
        {
          value: 'profileAttribute',
          label: this.$t(
            'delayToolboxSmallRelativeDateSelectOptionProfileAttribute'
          )
        },
        {
          value: 'eventAttribute',
          label: this.$t(
            'delayToolboxSmallRelativeDateSelectOptionEventProperty'
          )
        }
      ]
    }
  },

  computed: {
    profileAttributes() {
      return this.$store.state.project.attributes
    }
  },

  methods: {
    saveChanges(changes) {
      this.workflowApi.updateAction(this.action.id, {
        options: Object.assign({}, this.action.options, changes)
      })
    },

    updateAllowedResumeWindow(startTime, endTime) {
      this.saveChanges({
        startSafeTime: startTime,
        endSafeTime: endTime
      })
    },

    updateInterval(interval) {
      this.saveChanges({
        interval
      })
    },

    updateBeforeAfter(beforeAfterOption) {
      this.saveChanges({
        beforeOrAfter: beforeAfterOption.value
      })
    },

    updateProfileAttribute(profileAttribute) {
      this.saveChanges({
        profileAttribute
      })
    },

    updateRelativeTo(relativeToOption) {
      this.saveChanges({
        relativeTo: relativeToOption.value
      })
    },

    updateEventAttribute(event) {
      this.saveChanges({
        eventAttribute: event.target.value
      })
    }
  }
}
</script>
<style scoped>
.main-ct {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label-input-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.date-settings-ct {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.label-new-ui {
  color: #4b515f;
  font-weight: 600;
}
</style>
