<template>
  <TabView @tab-change="setPreviewLanguage">
    <TabPanel
      v-for="(lang, index) in sortedLanguages"
      :header="getLanguage(lang)"
      :tabindex="index"
      :key="lang"
    >
      <device-message
        v-model="messages[lang]"
        :is-default="lang === defaultLang"
        :lang="lang"
        :profile-attributes="profileAttributes"
        @input="onChange"
        :disabled="disabled"
      />
    </TabPanel>

    <div class="device-push-settings">
      <div class="label-input-group" v-if="withVoucherCodes">
        <span class="new-ui-label">{{ $t('voucherCodeTypeLabel') }}</span>
        <select-barcode-type
          v-model="voucher_code_enabled"
          @input="barcodeSettingChanged"
          :disabled="disabled"
        />
      </div>

      <div class="label-input-group" v-if="voucher_code_enabled || 0 === 1">
        <span class="new-ui-label">{{ $t('voucherCodePositionLabel') }}</span>
        <select-barcode-position
          v-model="voucher_code_position"
          @input="barcodeSettingChanged"
          :disabled="disabled"
        />
      </div>

      <div class="label-input-group" v-if="webPush">
        <span class="new-ui-label">{{ $t('urlLabel') }}</span>
        <InputText
          :disabled="disabled"
          v-model="url"
          type="text"
          @input="onChange"
        />
      </div>

      <div v-if="webPush">
        <span class="new-ui-label">{{ $t('iconLabel') }}</span>
        <div style="display: flex">
          <label
            style="flex: 1; margin-right: 10px"
            class="btn btn-default btn-file"
            :class="{ disabled: processingImageUpload }"
          >
            {{ $t('uploadAnIconLabel') }}
            <input
              type="file"
              style="display: none"
              @change="$emit('uploadIcon', $event)"
              :disabled="disabled"
            />
          </label>
          <button
            style="flex: 1; margin-left: 10px"
            class="btn btn-default"
            :disabled="!iconUrl || disabled"
            @click="$emit('removeIconUrl')"
          >
            {{ $t('removeLabel') }}
          </button>
        </div>
      </div>

      <div class="label-input-group">
        <span class="new-ui-label">{{ $t('pictureVariantLabel') }}</span>
        <Dropdown
          v-model="pictureVariant"
          :options="defaultPictureOptions"
          @change="pictureVariantChanged"
          option-label="name"
          option-value="value"
          :disabled="disabled"
        />
      </div>

      <div class="label-input-group" v-if="pictureVariant !== 'none'">
        <span class="new-ui-label">{{ $t('pictureLabel') }}</span>
        <InputText
          v-if="pictureVariant === 'url'"
          type="text"
          :value="pictureUrl"
          @change="$emit('pictureURLChanged', $event)"
          :disabled="disabled"
        />
        <div v-if="pictureVariant === 'upload'" style="display: flex">
          <label
            style="flex: 1; margin-right: 10px"
            class="btn btn-default btn-file"
            :class="{ disabled: processingImageUpload }"
          >
            {{ $t('uploadAPictureLabel') }}
            <input
              type="file"
              style="display: none"
              @change="$emit('uploadPicture', $event)"
              :disabled="disabled"
            />
          </label>
          <button
            style="flex: 1; margin-left: 10px"
            class="btn btn-default"
            :disabled="!pictureUrl || disabled"
            @click="$emit('removePictureUrl')"
          >
            {{ $t('removeLabel') }}
          </button>
        </div>
      </div>

      <div class="label-input-group" v-if="!webPush">
        <span class="new-ui-label">{{ $t('defaultActionLabel') }}</span>
        <Dropdown
          v-model="defaultAction.type"
          :options="defaultActionOptions"
          @change="onChange"
          option-label="name"
          option-value="value"
          :disabled="disabled"
        />
      </div>

      <div
        v-if="!webPush && ['url', 'deeplink'].includes(defaultAction.type)"
        class="label-input-group"
      >
        <span class="new-ui-label">{{ defaultActionLabel }}</span>
        <InputText
          v-model="defaultAction.value"
          type="text"
          @input="onChange"
          :disabled="disabled"
        />
      </div>

      <div class="label-input-group" v-if="ios">
        <span class="new-ui-label">{{ $t('interruptionLevel') }}</span>
        <Dropdown
          v-model="interruptionLevel"
          :options="interruptionLevelOptions"
          @change="onChange"
          option-label="name"
          option-value="value"
          :disabled="disabled"
        />
      </div>

      <div class="label-input-group" v-if="!webPush">
        <span class="new-ui-label">{{ $t('payloadLabel') }}</span>
        <div
          v-for="(payloadValue, index) in payload"
          style="display: flex; align-items: center"
        >
          <InputText
            v-model="payloadValue.key"
            type="text"
            style="flex: 1; margin-right: 20px"
            :placeholder="$t('keyPlaceholderLabel')"
            @input="onChange"
            :disabled="disabled"
          />
          <InputText
            v-model="payloadValue.value"
            type="text"
            style="flex: 1"
            :placeholder="$t('valuePlaceholderLabel')"
            @input="onChange"
            :disabled="disabled"
          />

          <button
            class="close"
            style="margin: 0 20px"
            @click="removePayload(index)"
            :disabled="disabled"
          >
            ×
          </button>
        </div>
        <Button
          style="width: fit-content"
          @click="addPayload"
          :label="$t('addPayloadLabel')"
          :disabled="disabled"
        />
      </div>
    </div>
  </TabView>
</template>
<script>
import ISO6391 from 'iso-639-1'
import MySelect from '@/components/Select'
import DeviceMessage from '@/components/DeviceMessage'
import SelectBarcodePosition from '@/components/SelectBarcodePosition'
import SelectBarcodeType from '@/components/SelectBarcodeType'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'

export default {
  components: {
    MySelect,
    DeviceMessage,
    SelectBarcodeType,
    SelectBarcodePosition,
    TabView,
    TabPanel,
    InputText,
    Dropdown,
    Button
  },
  props: [
    'value',
    'defaultLang',
    'languages',
    'processingImageUpload',
    'profileAttributes',
    'webPush',
    'ios',
    'withVoucherCodes',
    'disabled'
  ],

  emits: ['preview-language-change'],

  data() {
    let data = JSON.parse(JSON.stringify(this.value))

    return Object.assign(
      {},
      {
        interruptionLevel: 1,
        pictureVariant: 'none',
        variantImages: {
          none: null,
          upload: null,
          url: null
        },
        voucher_code_enabled: 0,
        voucher_code_position: 0
      },
      data
    )
  },

  computed: {
    defaultPictureOptions() {
      return [
        {
          name: this.$t('devicePushOptionNone'),
          value: 'none'
        },
        {
          name: this.$t('devicePushPictureOptionUpload'),
          value: 'upload'
        },
        {
          name: this.$t('devicePushPictureOptionURL'),
          value: 'url'
        }
      ]
    },

    defaultActionOptions() {
      return [
        {
          name: this.$t('devicePushOptionNone'),
          value: 'none'
        },
        {
          name: this.$t('devicePushActionOptionGoToURL'),
          value: 'url'
        },
        {
          name: this.$t('devicePushActionOptionGoToDeeplink'),
          value: 'deeplink'
        }
      ]
      /**
       * to be added back eventually
       * {
       *    name: 'In-App Message',
       *    value: 'inapp'
       * }
       */
    },

    defaultActionLabel() {
      const mapping = {
        url: 'Open URL ',
        deeplink: 'Deeplink'
      }

      return mapping[this.defaultAction.type]
    },

    interruptionLevelOptions() {
      return [
        {
          name: this.$t('interruptionLevelPassive'),
          value: 0
        },
        {
          name: this.$t('interruptionLevelActive'),
          value: 1
        },
        {
          name: this.$t('interruptionLevelTimeSensitive'),
          value: 2
        },
        {
          name: this.$t('interruptionLevelCritical'),
          value: 3
        }
      ]
    },

    sortedLanguages() {
      const languages = Object.keys(this.messages)
        .filter((lang) => lang !== this.defaultLang)
        .sort((lang1, lang2) =>
          ISO6391.getName(lang1).localeCompare(ISO6391.getName(lang2))
        )
      languages.unshift(this.defaultLang)

      return languages
    },

    currentPictureVariant() {
      if (this.pictureVariant === 'none') return null

      return this.variantImages[this.pictureVariant]
    }
  },

  watch: {
    languages: {
      immediate: true,
      handler(languages) {
        let changes = false
        languages.forEach((lang) => {
          if (!this.messages[lang]) {
            this.$set(this.messages, lang, {
              title: '',
              text: ''
            })
            changes = true
          }
        })

        Object.keys(this.messages).forEach((lang) => {
          if (!languages.includes(lang)) {
            this.$delete(this.messages, lang)
            changes = true
          }
        })

        if (changes) this.onChange()
      }
    },
    value: {
      deep: true,
      handler() {
        Object.assign(this.$data, JSON.parse(JSON.stringify(this.value)))
      }
    },
    pictureUrl: function (newValue) {
      this.variantImages[this.pictureVariant] = newValue
    }
  },

  methods: {
    pictureVariantChanged() {
      this.pictureUrl = this.currentPictureVariant
      this.$nextTick(function () {
        this.onChange()
      })
    },

    barcodeSettingChanged() {
      this.$nextTick(function () {
        this.onChange()
      })
    },

    onChange() {
      //@todo we should probably debounce this
      this.$nextTick(function () {
        this.$emit('input', JSON.parse(JSON.stringify(this.$data)))
      })
    },

    addPayload() {
      this.payload.push({
        key: '',
        value: ''
      })
      this.onChange()
    },

    removePayload(index) {
      this.$delete(this.payload, index)
      this.onChange()
    },

    getLanguage(languageCode) {
      return ISO6391.getName(languageCode)
    },

    setPreviewLanguage({ index }) {
      this.$emit('preview-language-change', this.sortedLanguages[index])
    }
  }
}
</script>
<style scoped>
.label-input-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.new-ui-label {
  color: #4b515f;
  font-weight: 600;
}

.device-push-settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
</style>
