<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>{{ $t('actionReportingDecisionStatsLabel') }}:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="user" />
        <div class="stats-bar-item-title">
          {{ $t('actionReportingStatsArrivedLabel') }}:
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(arrivedTotal) }}
        </div>
      </div>
      <div class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #22a96a; color: #fff"
        >
          {{ $t('actionReportingYesLabel') }}
        </div>
        <div class="stats-bar-item-title">
          {{ $t('actionReportingNumberToYesLabel') }}:
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(yesTotal) }}
          ({{ percentageOfFilter(yesTotal, arrivedTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #e54144; color: #fff"
        >
          {{ $t('actionReportingNoLabel') }}
        </div>
        <div class="stats-bar-item-title">
          {{ $t('actionReportingNumberToNoLabel') }}:
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(noTotal) }}
          ({{ percentageOfFilter(noTotal, arrivedTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <line-chart
      :chart-data="chartData"
      :title="$t('actionReportingHowManyProfilesArrived')"
      title-class="action-color"
      :y-label="$t('actionReportingNumberOfProfiles')"
      style="margin-bottom: 30px"
    />
    <bar-chart
      :chart-data="decisionChartData"
      title-class="action-color"
    />
  </div>
</template>
<script>
import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'

import ActionStatsLoaderMixin from './Charts/ActionStatsLoaderMixin'
import LineChart from '@/components/DateLineChart'
import BarChart from './Charts/StackedBar'
import { numberFilter, percentageOfFilter } from '@/helpers/filters'

export default {
  components: {
    LineChart,
    BarChart
  },
  mixins: [ActionStatsLoaderMixin, ReportingMixin, TrendLineMixin],

  data() {
    return {
      numberFilter,
      percentageOfFilter
    }
  },

  computed: {
    arrivedTotal() {
      return this.yesTotal + this.noTotal
    },

    yesTotal() {
      return this.getTotal('Decision:evaluatedYes')
    },

    noTotal() {
      return this.getTotal('Decision:evaluatedNo')
    },

    evaluatedYesStats() {
      return this.getStats('Decision:evaluatedYes')
    },

    arrivedStats() {
      return this.getCombinedStats(
        'Decision:evaluatedYes',
        'Decision:evaluatedNo'
      )
    },

    yesPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        evaluatedYes = this.evaluatedYesStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((evaluatedYes[idx] / entered) * 100) || 0)
      )

      return total
    },

    noPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        yesPercent = this.yesPercentStats

      enteredData.forEach((entered, idx) =>
        total.push(entered > 0 ? 100 - yesPercent[idx] : 0)
      )

      return total
    },

    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('actionReportingStatsArrivedLabel'),
            backgroundColor: 'RGBA(245, 177, 95, .2)',
            borderColor: 'RGBA(245, 177, 95, 1)',
            borderWidth: 1,
            data: this.arrivedStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingStatsTrendLineLabel'),
            data: this.getTrendLine(this.arrivedStats),
            backgroundColor: 'RGBA(245, 177, 95, 1)',
            borderColor: 'RGBA(245, 177, 95, 1)',
            spanGaps: true
          }
        ]
      }
    },

    decisionChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('actionReportingYesLabel'),
            backgroundColor: 'RGBA(0, 170, 108, .2)',
            borderColor: 'RGBA(0, 170, 108, 1)',
            borderWidth: 1,
            data: this.yesPercentStats
          },
          {
            label: this.$t('actionReportingNoLabel'),
            backgroundColor: 'RGBA(235, 64, 73, .2)',
            borderColor: 'RGBA(235, 64, 73, 1)',
            borderWidth: 1,
            data: this.noPercentStats
          }
        ]
      }
    }
  }
}
</script>
