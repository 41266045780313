<template>
  <div>
    <input
      v-if="operators[operator] && operators[operator].requiresAmount"
      v-model="amount"
      class="form-control form-number"
      type="number"
      min="0"
      :placeholder="$t('insertNumberPlaceholder')"
      @input="onInput"
      :disabled="readOnly"
    />

    <select2 :disabled="readOnly" v-model="operator" @input="onInput">
      <option v-for="(op, value) in operators" :value="value">
        {{ op.title }}
      </option>
    </select2>
  </div>
</template>
<script>
import Select2 from '@/components/Select2'

export default {
  components: { Select2 },
  props: ['value', 'readOnly'],

  data() {
    return {
      operator: this.value.operator || 'user_calendar_today',
      amount: this.value.amount || '',
      date: this.value.value || '',
      operators: {
        user_calendar_today: {
          title: 'Today (by user timezone)',
          requiresAmount: false
        },
        user_calendar_days_ago: {
          title: 'Calendar days ago (by user timezone)',
          requiresAmount: true
        },
        user_calendar_days_ahead: {
          title: 'Calendar days ahead (by user timezone)',
          requiresAmount: true
        }
      }
    }
  },

  mounted() {
    const operators = Object.keys(this.operators)
    this.operator = operators.includes(this.value.operator)
      ? this.value.operator
      : operators[0]
    this.onInput()
  },

  methods: {
    onInput() {
      this.$emit('input', {
        operator: this.operator,
        amount: this.amount
      })
    }
  }
}
</script>
