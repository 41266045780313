<template>
  <div class="card">
    <div class="card__title">
      {{ renderedTitle }}
    </div>
    <div class="card__icon" v-if="renderedIcon !== null">
      <img :src="icon" />
    </div>
    <div class="card__text" style="white-space: pre-wrap;">
      <span>{{ renderedMessage }}</span>
    </div>
    <div class="card__domain">
      {{ $t('devicePreviewVia').toLowerCase() }}
      {{ $t('devicePreviewTextExampleWebsite') }}
    </div>
    <div class="card__icon--cancel">
      <i class="icon-cancel"></i>
    </div>
    <div class="card__icon--settings">
      <i class="icon-cog"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PushPreviewWebWindowsFirefox',

  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    icon: {
      type: String
    }
  },
  computed: {
    renderedTitle() {
      if (this.title === '' || this.title === null) {
        return this.$t('devicePreviewTextNotificationPreview')
      } else {
        return this.title
      }
    },
    renderedMessage() {
      if (this.message === '' || this.message === null) {
        return this.$t('devicePreviewTextNotificationPreviewMessage')
      } else {
        return this.message
      }
    },
    renderedIcon() {
      if (this.icon === '' || this.icon === null) {
        return null
      } else {
        return this.icon
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');
.card {
  height: 125px;
  width: 365px;
  background: #ffffff;
  box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.18);
  position: relative;

  &__title {
    position: absolute;
    left: 2.2%;
    right: 10.16%;
    top: 7.2%;
    bottom: 76.8%;
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2f3032;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    position: absolute;
    left: 2.47%;
    right: 74.73%;
    top: 27.2%;
    bottom: 6.4%;
    width: 83px;
    height: 83px;

    & > img {
      width: 83px;
      min-width: 83px;
      max-width: 83px;
      min-height: 83px;
      max-height: 83px;
      height: 83px;
    }
  }

  &__text {
    position: absolute;
    left: 26.92%;
    right: 5.24%;
    top: 27.2%;
    bottom: 31.2%;
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: #1e2020;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__domain {
    position: absolute;
    left: 26.92%;
    right: 10.16%;
    top: 77.6%;
    bottom: 7.2%;
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;
    color: #888989;
  }

  &__icon {
    &--cancel {
      position: absolute;
      width: 10px;
      height: 10px;
      left: 344px;
      top: 10px;
    }

    &--settings {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 340px;
      top: 101px;
    }
  }
}
</style>
