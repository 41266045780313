<template>
  <TabView @tab-change="generateKey">
    <TabPanel header="Setup">
      <div class="setup-tab-ct">
        <div class="device-toggle-ct">
          <span class="new-ui-label-no-width">Push to</span>
          <div class="label-switch-group">
            <span class="new-ui-label" @click="toggle('ios')">
              <icon glyph="apple" />
              {{ $t('pushToolboxLargeIosLabel') }}
            </span>
            <InputSwitch
              :value="!!config.ios"
              @change="toggle('ios')"
              :disabled="!iosPushEnabled || readOnly"
            />
          </div>
          <div class="label-switch-group">
            <span class="new-ui-label" @click="toggle('android')">
              <icon glyph="android" />
              {{ $t('pushToolboxLargeAndroidLabel') }}
            </span>
            <InputSwitch
              :value="!!config.android"
              @change="toggle('android')"
              :disabled="!androidPushEnabled || readOnly"
            />
          </div>
          <div class="label-switch-group">
            <span class="new-ui-label" @click="toggle('web')">
              <icon glyph="web" />
              {{ $t('pushToolboxLargeWebLabel') }}
            </span>
            <InputSwitch
              :value="!!config.web"
              @change="toggle('web')"
              :disabled="!webPushEnabled || readOnly"
            />
          </div>
        </div>

        <div>
          <div class="label-input-group">
            <span class="new-ui-label-no-width">
              {{ $t('pushToolboxLargeDefaultLanguageLabel') }}
            </span>
            <language-selector
              :value="config.defaultLang"
              @input="setDefaultLang"
              :disabled="readOnly"
            />
          </div>
        </div>

        <div>
          <div class="label-input-group">
            <span class="new-ui-label-no-width">
              {{ $t('pushToolboxLargeAdditionalLanguageLabel') }}
            </span>
            <multi-language-selector
              :key="config.defaultLang"
              :value="filteredLanguages"
              :default-language-code="config.defaultLang"
              @input="setLanguages"
              :disabled="readOnly"
            />
          </div>
        </div>

        <national-exclusion-check-settings
          v-if="
            $store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
            $store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
          "
          v-model="config.exclusion"
          :disabled="readOnly"
        />

        <div v-if="config.hasOwnProperty('promotion_id')">
          <span>{{ $t('pushToolboxLargePromotionLabel') }}</span>
          <div>
            <el-autocomplete
              style="width: 100%"
              clearable
              :debounce="500"
              :placeholder="placeholder"
              :disabled="readOnly || !loaded"
              v-model="selectedPromotionName"
              :fetch-suggestions="querySearchPromotions"
              @select="updatePromotion"
              @clear="updatePromotion({})"
            />
          </div>
        </div>

        <div
          class="label-input-group"
          v-if="
            config.hasOwnProperty('promotion_amount_required') &&
            config.promotion_amount_required
          "
        >
          <span>
            {{ $t('pushToolboxLargePromotionAmountLabel') }}
          </span>
          <InputText
            type="number"
            v-model="config.promotion_amount"
            :disabled="readOnly"
          />
        </div>

        <div
          class="label-input-group"
          v-if="
            config.hasOwnProperty('promotion_duration_required') &&
            config.promotion_duration_required
          "
        >
          <span class="new-ui-label-no-width">
            {{ $t('pushToolboxLargePromotionDurationLabel') }}
          </span>
          <InputText
            type="number"
            v-model="config.promotion_duration"
            :disabled="readOnly"
          />
        </div>

        <div class="label-input-group" v-if="encryptionEnabled">
          <span class="new-ui-label-no-width">
            {{ $t('pushToolboxLargeEncryptionLabel') }}
          </span>
          <select-encryption
            v-model="config.encryption"
            :disabled="readOnly || (!iosEnabled && !androidEnabled)"
          />
        </div>

        <div v-if="iosEnabled || androidEnabled || webEnabled">
          <div class="label-input-group">
            <div class="label-input-group">
              <span class="new-ui-label-no-width">
                {{ $t('pushToolboxLargeOfflineUsersLabel') }}
              </span>
              <select-retry-type
                v-model="config.retry_type"
                :disabled="
                  readOnly || (!iosEnabled && !androidEnabled && !webEnabled)
                "
              />
            </div>
            <retry-for
              v-if="
                config.retry_type === 1 &&
                (iosEnabled || androidEnabled || webEnabled)
              "
              :retry_for="config.retry_for"
              @retry_for="config.retry_for = $event"
              :retry_for_period="config.retry_for_period"
              @retry_for_period="config.retry_for_period = $event"
              :disabled="
                readOnly || (!iosEnabled && !androidEnabled && !webEnabled)
              "
            />
            <retry-until
              style="width: 100%"
              v-if="
                config.retry_type === 2 &&
                (iosEnabled || androidEnabled || webEnabled)
              "
              v-model="config.retry_until"
              :disabled="
                readOnly || (!iosEnabled && !androidEnabled && !webEnabled)
              "
            />
          </div>
        </div>
      </div>
    </TabPanel>
    <TabPanel header="iOS" :disabled="!iosEnabled || !iosPushEnabled">
      <div class="flexbox">
        <div class="col-md-6">
          <device-push
            :key="channelTabRefreshKey"
            v-if="iosEnabled"
            v-model="config.ios"
            :profile-attributes="profileAttributes"
            :default-lang="config.defaultLang"
            :languages="config.languages"
            :ios="true"
            :with-voucher-codes="voucherCodesEnabled('ios')"
            @uploadPicture="onFileSelected('ios', 'pictureUrl', $event)"
            @removePictureUrl="onRemoveImage('ios', 'pictureUrl')"
            @pictureURLChanged="
              onPictureURLChanged('ios', 'pictureUrl', $event)
            "
            @preview-language-change="changePreviewLanguage"
            :disabled="readOnly"
          />

          <div class="web-settings">
            <ignore-subscription
              v-if="iosEnabled && !consentPreferencesAvailable"
              root-class="label-switch-group"
              :read-only="readOnly"
              :value="config.ios.ignore_subscription"
              @input="onIgnoreSubscriptionChange($event, 'ios')"
            />

            <div
              class="label-input-group"
              v-if="iosEnabled && consentPreferencesAvailable"
            >
              <div class="label-switch-group">
                <span class="new-ui-label">
                  {{ $t('consentPreferenceType') }}
                </span>
                <InputSwitch
                  :disabled="readOnly"
                  v-model="config.ios.consent_preference.enabled"
                />
              </div>
              <select-consent-preference
                v-if="config.ios.consent_preference.enabled"
                :value="config.ios.consent_preference.preference"
                :channel="ConsentChannels.PUSH"
                :preferences="consentPreferences"
                :read-only="readOnly"
                @input="updateConsentPreference('ios', $event)"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="action-ct" style="text-align: right">
            <el-dropdown v-if="!readOnly" trigger="click">
              <div class="action-btn">
                <span>{{ $t('actions') }}</span>
                <icon style="margin-bottom: 2px;" glyph="chevron-down" size="14px"/>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="setDialogVisible = true">
                    <i class="pi pi-file" />
                    {{ $t('applyTemplate') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <apply-template-dialog
              :visible="setDialogVisible"
              @setVisible="setDialogVisible = false"
              :channel="MessageTypes.PUSH_IOS"
              :has-content="hasContent('ios')"
              @applyMessageTemplate="importTemplate($event, 'ios')"
            />
          </div>
          <push-preview-section
            v-if="iosEnabled && config.ios.messages[config.defaultLang]"
            :app-icon="$store.getters.companyLogo"
            :app-name="$store.state.app.project.name"
            :title="getIosMessageTitle"
            :text="messageTextIOs"
            :icon="config.ios.pictureUrl"
            platform="ios"
            :new-frame="true"
          />
          <div
            class="template-summary"
            v-tooltip:top="formatTemplateTooltip('ios')"
          >
            {{ formatTemplateInfo('ios') }}
          </div>
        </div>
      </div>
    </TabPanel>
    <TabPanel
      header="Android"
      :disabled="!androidEnabled || !androidPushEnabled"
    >
      <div class="flexbox">
        <div class="col-md-6">
          <device-push
            :key="channelTabRefreshKey"
            v-if="androidEnabled"
            v-model="config.android"
            :profile-attributes="profileAttributes"
            :default-lang="config.defaultLang"
            :languages="config.languages"
            :with-voucher-codes="voucherCodesEnabled('android')"
            @uploadPicture="onFileSelected('android', 'pictureUrl', $event)"
            @removePictureUrl="onRemoveImage('android', 'pictureUrl')"
            @pictureURLChanged="
              onPictureURLChanged('android', 'pictureUrl', $event)
            "
            @preview-language-change="changePreviewLanguage"
            :disabled="readOnly"
          />

          <div class="web-settings">
            <ignore-subscription
              v-if="androidEnabled && !consentPreferencesAvailable"
              root-class="label-switch-group"
              :read-only="readOnly"
              :value="config.android.ignore_subscription"
              @input="onIgnoreSubscriptionChange($event, 'android')"
            />
            <div
              class="label-input-group"
              v-if="androidEnabled && consentPreferencesAvailable"
            >
              <div class="label-switch-group">
                <span class="new-ui-label">
                  {{ $t('consentPreferenceType') }}
                </span>
                <InputSwitch
                  :disabled="readOnly"
                  v-model="config.android.consent_preference.enabled"
                />
              </div>
              <select-consent-preference
                v-if="config.android.consent_preference.enabled"
                :value="config.android.consent_preference.preference"
                :channel="ConsentChannels.PUSH"
                :preferences="consentPreferences"
                :read-only="readOnly"
                @input="updateConsentPreference('android', $event)"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="action-ct" style="text-align: right">
            <el-dropdown v-if="!readOnly" trigger="click">
              <div class="action-btn">
                <span>{{ $t('actions') }}</span>
                <icon style="margin-bottom: 2px;" glyph="chevron-down" size="14px"/>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="setDialogVisible = true">
                    <i class="pi pi-file" />
                    {{ $t('applyTemplate') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <apply-template-dialog
              :visible="setDialogVisible"
              @setVisible="setDialogVisible = false"
              :channel="MessageTypes.PUSH_ANDROID"
              :has-content="hasContent('android')"
              @applyMessageTemplate="importTemplate($event, 'android')"
            />
          </div>
          <push-preview-section
            v-if="androidEnabled && config.android.messages[config.defaultLang]"
            :app-icon="$store.getters.companyLogo"
            :app-name="$store.state.app.project.name"
            :title="getAndroidMessageTitle"
            :text="messageTextAndroid"
            :icon="config.android.pictureUrl"
            platform="android"
            :new-frame="true"
          />
          <div
            class="template-summary"
            v-tooltip:top="formatTemplateTooltip('android')"
          >
            {{ formatTemplateInfo('android') }}
          </div>
        </div>
      </div>
    </TabPanel>
    <TabPanel header="Web" :disabled="!webEnabled || !webPushEnabled">
      <div class="col-md-6">
        <device-push
          :key="channelTabRefreshKey"
          v-if="webEnabled"
          v-model="config.web"
          :profile-attributes="profileAttributes"
          :default-lang="config.defaultLang"
          :languages="config.languages"
          :web-push="true"
          :with-voucher-codes="voucherCodesEnabled('web')"
          @uploadPicture="onFileSelected('web', 'pictureUrl', $event)"
          @removePictureUrl="onRemoveImage('web', 'pictureUrl')"
          @uploadIcon="onFileSelected('web', 'iconUrl', $event)"
          @removeIconUrl="onRemoveImage('web', 'iconUrl')"
          @pictureURLChanged="onPictureURLChanged('web', 'pictureUrl', $event)"
          @preview-language-change="changePreviewLanguage"
          :disabled="readOnly"
        />

        <div class="web-settings">
          <ignore-subscription
            v-if="webEnabled && !consentPreferencesAvailable"
            root-class="label-switch-group"
            :read-only="readOnly"
            :value="config.web.ignore_subscription"
            @input="onIgnoreSubscriptionChange($event, 'web')"
          />

          <div
            class="label-input-group"
            v-if="webEnabled && consentPreferencesAvailable"
          >
            <div class="label-switch-group">
              <span class="new-ui-label">
                {{ $t('consentPreferenceType') }}
              </span>
              <InputSwitch
                v-model="config.web.consent_preference.enabled"
                :disabled="readOnly"
              />
            </div>
            <select-consent-preference
              v-if="config.web.consent_preference.enabled"
              :value="config.web.consent_preference.preference"
              :channel="ConsentChannels.PUSH"
              :preferences="consentPreferences"
              :read-only="readOnly"
              @input="updateConsentPreference('web', $event)"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="action-ct" style="text-align: right">
          <el-dropdown v-if="!readOnly" trigger="click">
            <div class="action-btn">
              <span>{{ $t('actions') }}</span>
              <icon style="margin-bottom: 2px;" glyph="chevron-down" size="14px"/>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="setDialogVisible = true">
                  <i class="pi pi-file" />
                  {{ $t('applyTemplate') }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <apply-template-dialog
            :visible="setDialogVisible"
            @setVisible="setDialogVisible = false"
            :channel="MessageTypes.PUSH_WEB"
            :has-content="hasContent('web')"
            @applyMessageTemplate="importTemplate($event, 'web')"
          />
        </div>
        <web-push
          v-if="webEnabled && config.web.messages[config.defaultLang]"
          class="web-push"
        >
          <template #windows-push>
            <div class="preview-panel-ct">
              <img class="push-icon" src="@/images/chrome.png" />
              <push-preview-web-windows-chrome
                :title="getWebMessageTitle"
                :icon="config.web.iconUrl"
                :message="messageTextWeb"
                :picture="config.web.pictureUrl"
              />
            </div>
            <div class="preview-panel-ct">
              <img class="push-icon" src="@/images/firefox.png" />
              <push-preview-web-windows-firefox
                :title="getWebMessageTitle"
                :icon="config.web.iconUrl"
                :message="messageTextWeb"
                :picture="config.web.pictureUrl"
              />
            </div>
          </template>

          <template #ios-push>
            <div class="preview-panel-ct">
              <img class="push-icon" src="@/images/safari.png" />
              <push-preview-web-mac-safari
                :title="getWebMessageTitle"
                :icon="config.web.iconUrl"
                :message="messageTextWeb"
              />
            </div>

            <div class="preview-panel-ct">
              <img class="push-icon" src="@/images/chrome.png" />
              <push-preview-web-mac-chrome
                :title="getWebMessageTitle"
                :icon="config.web.iconUrl"
                :message="messageTextWeb"
              />
            </div>

            <div class="preview-panel-ct">
              <img class="push-icon" src="@/images/firefox.png" />
              <push-preview-web-mac-firefox
                :title="getWebMessageTitle"
                :icon="config.web.iconUrl"
                :message="messageTextWeb"
              />
            </div>
          </template>

          <template #android-push>
            <div class="preview-panel-ct">
              <img class="push-icon" src="@/images/chrome.png" />
              <push-preview-web-android
                browser="chrome"
                :title="getWebMessageTitle"
                :icon="config.web.iconUrl"
                :message="messageTextWeb"
                :picture="config.web.pictureUrl"
                show-picture-on-hover
              />
            </div>

            <div class="preview-panel-ct">
              <img class="push-icon" src="@/images/firefox.png" />
              <push-preview-web-android
                browser="firefox"
                :title="getWebMessageTitle"
                :icon="config.web.iconUrl"
                :message="messageTextWeb"
              />
            </div>
          </template>
        </web-push>
        <div
          class="template-summary"
          v-tooltip:top="formatTemplateTooltip('web')"
        >
          {{ formatTemplateInfo('web') }}
        </div>
      </div>
    </TabPanel>
  </TabView>
</template>
<script>
import * as Toastr from 'toastr'
import LanguageSelector from '@/components/LanguageSelector'
import MultiLanguageSelector from '@/components/MultiLanguageSelector'
import DevicePush from '@/components/DevicePush'
import DevicePushPreview from '@/components/DevicePushPreview'
import DevicePushMessagePreview from '@/components/DevicePushMessagePreview'
import WebPush from '@/components/WebPush'
import WebPushWindows from '@/components/WebPushWindows'
import WebPushApple from '@/components/WebPushApple'
import WebPushAndroid from '@/components/WebPushAndroid'
import { MessageTypes } from './MessageTypesEnum'
import MxaApi from '@/libs/MxaApi'
import Tabs from '@/components/TabSet'
import Tab from '@/components/Tab'
import SelectEncryption from '@/components/SelectEncryption'
import SelectRetryType from '@/components/SelectRetryType'
import RetryUntil from '@/components/RetryUntil'
import RetryFor from '@/components/RetryFor'
import IgnoreSubscription from '@/components/IgnoreSubscription'
import NationalExclusionCheckSettings from '@/components/NationalExclusionCheckSettings'
import TemplateSection from './TemplateSection.vue'
import XpApi from '@/libs/XpApi'
import SelectConsentPreference from '@/components/SelectConsentPreference'
import { ConsentChannels } from '@/enums/ConsentChannelEnum'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import PushPreviewWebAndroid from '@/components/DevicePreview/PushPreviewWebAndroid'
import PushPreviewWebWindowsFirefox from '@/components/DevicePreview/PushPreviewWebWindowsFirefox'
import { value } from 'lodash/seq'
import PushPreviewWebWindowsChrome from '@/components/DevicePreview/PushPreviewWebWindowsChrome'
import PushPreviewWebMacSafari from '@/components/DevicePreview/PushPreviewWebMacSafari'
import PushPreviewWebMacChrome from '@/components/DevicePreview/PushPreviewWebMacChrome'
import PushPreviewWebMacFirefox from '@/components/DevicePreview/PushPreviewWebMacFirefox'
import PushPreviewSection from '@/components/DevicePreview/PushPreviewSection'
import ApplyTemplateDialog from '@/components/ApplyTemplateDialog'
import moment from 'moment'
import PushActions from './PushActionsEnum'

export default {
  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi'],

  components: {
    ApplyTemplateDialog,
    PushPreviewSection,
    PushPreviewWebMacFirefox,
    PushPreviewWebMacChrome,
    PushPreviewWebMacSafari,
    PushPreviewWebWindowsChrome,
    PushPreviewWebWindowsFirefox,
    PushPreviewWebAndroid,
    TemplateSection,
    IgnoreSubscription,
    RetryFor,
    RetryUntil,
    SelectRetryType,
    SelectEncryption,
    Tabs,
    Tab,
    LanguageSelector,
    MultiLanguageSelector,
    DevicePush,
    DevicePushPreview,
    DevicePushMessagePreview,
    WebPush,
    WebPushWindows,
    WebPushApple,
    WebPushAndroid,
    NationalExclusionCheckSettings,
    SelectConsentPreference,
    TabView,
    TabPanel,
    InputSwitch,
    InputText
  },

  data() {
    const config = Object.assign(
      {
        defaultLang: 'en',
        languages: ['en'],
        ios: null,
        android: null,
        web: null,
        project_id: null,
        encryption: null,
        retry_type: 1,
        retry_for: 4,
        retry_for_period: 'weeks',
        retry_until: null,
        exclusion: {
          check: false,
          category: null
        }
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    if (config.ios && !config.ios.consent_preference) {
      config.ios.consent_preference = {
        enabled: false,
        preference: null
      }
    }

    if (config.android && !config.android.consent_preference) {
      config.android.consent_preference = {
        enabled: false,
        preference: null
      }
    }

    if (config.web && !config.web.consent_preference) {
      config.web.consent_preference = {
        enabled: false,
        preference: null
      }
    }

    return {
      promotion: {},
      selectedPromotionName: '',
      loaded: false,
      MessageTypes,
      ConsentChannels,
      config,
      messagePreviewLanguage: '',
      panelKeyState: 1,
      setDialogVisible: false
    }
  },

  computed: {
    projectId() {
      return this.$store.state.app.project.id
    },

    iosEnabled() {
      return !!this.config.ios
    },

    androidEnabled() {
      return !!this.config.android
    },

    webEnabled() {
      return !!this.config.web
    },

    encryptionEnabled() {
      return this.$store.getters.isFeatureEnabled('pushEncryptionEnabled')
    },

    profileAttributes() {
      return this.$store.state.project.attributes
    },

    filteredLanguages() {
      return this.config.languages.filter(
        (lang) => lang !== this.config.defaultLang
      )
    },

    iosPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.ios_push
    },

    androidPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.android_push
    },
    webPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.web_push
    },

    placeholder() {
      return this.loaded
        ? this.$t('selectOptionPlaceholder')
        : this.$t('loadingPlaceholder')
    },

    messageTextIOs() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      const messageText = this.config.ios.messages[language].text
      if ((this.config.ios.voucher_code_enabled || 0) === 0) return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.ios.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    getIosMessageTitle() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      return this.config.ios.messages[language].title
    },

    getAndroidMessageTitle() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      return this.config.android.messages[language].title
    },

    messageTextAndroid() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      const messageText = this.config.android.messages[language].text
      if ((this.config.android.voucher_code_enabled || 0) === 0)
        return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.android.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    messageTextWeb() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      const messageText = this.config.web.messages[language].text
      if ((this.config.web.voucher_code_enabled || 0) === 0) return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.web.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    consentPreferences() {
      return this.$store.state.project.consentPreferences
    },

    consentPreferencesAvailable() {
      return this.$store.getters.isFeatureEnabled('consentPreferences')
    },

    getWebMessageTitle() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      return this.config.web.messages[language].title
    },

    channelTabRefreshKey() {
      // This is used to refresh the channel tabs
      // Before this, adding new languages would not reflect in the different channel tabs
      return this.config.defaultLang + this.config.languages.length
    },

    getCurrentLanguage() {
      return this.messagePreviewLanguage || this.config.defaultLang
    }
  },

  created() {
    this.$store.dispatch('fetchAttributes')
    this.$store.dispatch('fetchTemplates', MessageTypes.PUSH_IOS)
    this.$store.dispatch('fetchTemplates', MessageTypes.PUSH_ANDROID)
    this.$store.dispatch('fetchTemplates', MessageTypes.PUSH_WEB)
    if (this.config.promotion_id) {
      const foundPromotion = this.$store.state.project.promotions.find(
        (promotion) => promotion.id === this.config.promotion_id
      )
      if (foundPromotion === undefined) {
        XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
          params: { id: this.config.promotion_id }
        }).then(({ data }) => {
          if (data.length === 0) {
            this.updatePromotion({})
          } else {
            this.promotion = data[0]
            this.selectedPromotionName = data[0].name
          }
        })
      } else {
        this.promotion = foundPromotion
        this.selectedPromotionName = foundPromotion.name
      }
    }

    if (this.encryptionEnabled && this.config.encryption == null) {
      this.config.encryption = 1
    }

    if (!this.readOnly) {
      this.convertSubscriptionSettings()
    }

    this.loaded = true
  },

  beforeDestroy() {
    // @todo jd, check for changes before performing a save
    const config = JSON.parse(JSON.stringify(this.config))
    this.workflowApi.updateAction(this.action.id, { config })
  },

  methods: {
    value,
    toggle(device) {
      if (device === 'android' && !this.androidPushEnabled) {
        return
      }

      if (device === 'ios' && !this.iosPushEnabled) {
        return
      }

      if (this.config[device]) {
        this.$set(this.config, device, null)
      } else {
        const messages = {}
        this.config.languages.forEach((lang) => {
          messages[lang] = {
            title: '',
            text: ''
          }
        })

        if (device === 'web') {
          this.$set(this.config, device, {
            messages,
            pictureUrl: '',
            iconUrl: '',
            url: '',
            ignore_subscription: 'default',
            consent_preference: {
              enabled: false,
              preference: null
            },
            voucher_code_enabled: 0,
            voucher_code_position: 1
          })

          return
        }

        this.$set(this.config, device, {
          messages,
          payload: [],
          pictureUrl: '',
          defaultAction: {
            type: 'none',
            value: ''
          },
          ignore_subscription: 'default',
          consent_preference: {
            enabled: false,
            preference: null
          },
          voucher_code_enabled: 0,
          voucher_code_position: 1
        })
      }
      this.updateConfig()
    },

    setDefaultLang(lang) {
      if (!this.config.languages.includes(lang)) {
        this.config.languages.push(lang)
      }
      this.config.defaultLang = lang
      this.updateConfig()
    },

    setLanguages(languages) {
      if (!languages.includes(this.config.defaultLang)) {
        languages.push(this.config.defaultLang)
      }
      this.config.languages = languages
      this.updateConfig()
    },

    onFileSelected(device, type, event) {
      const data = new FormData()
      data.append('image', event.target.files[0])
      event.target.value = null

      MxaApi.post(`projects/${this.projectId}/images`, data)
        .then(({ data }) => (this.config[device][type] = data.url))
        .catch(({ data }) => Toastr.error(data.title))
    },

    onRemoveImage(device, type) {
      this.config[device][type] = ''
    },

    onPictureURLChanged(device, type, event) {
      this.config[device][type] = event.target.value
    },

    updatePromotion(selectedPromotion) {
      this.promotion = selectedPromotion
      this.selectedPromotionName = selectedPromotion
        ? selectedPromotion.name
        : ''
      this.config.promotion_id = selectedPromotion ? selectedPromotion.id : ''
      if (this.promotion) {
        this.config.promotion_amount_required = this.promotion.amount_required
        this.config.promotion_duration_required =
          this.promotion.duration_required
        if (!this.promotion.amount_required) {
          this.config.promotion_amount = null
        }
        if (!this.promotion.duration_required) {
          this.config.promotion_duration = null
        }
      } else {
        this.config.promotion_amount_required = false
        this.config.promotion_duration_required = false
      }

      this.updateConfig()
    },

    updateConfig() {
      this.workflowApi.updateAction(this.action.id, { config: this.config })
    },

    onIgnoreSubscriptionChange(type, channel) {
      this.config[channel].ignore_subscription = type
    },

    voucherCodesEnabled(channel) {
      return (
        (this.workflow?.features?.voucher_codes || false) &&
        ((this.config?.[channel]?.voucher_code_enabled || 0) === 1 ||
          this.$store.getters.isFeatureEnabled('allowVoucherCodes'))
      )
    },

    querySearchPromotions(queryString, cb) {
      XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
        params: { name: queryString }
      }).then(({ data }) => {
        const list = data.map((item) => {
          item.value = item.name
          return item
        })
        cb(list)
      })
    },

    convertSubscriptionSettings() {
      ;['ios', 'android', 'web'].forEach((type) => {
        if (
          this.action.config?.[type]?.ignore_subscription === 'ignore' &&
          this.$store.getters.isFeatureEnabled('consentPreferences')
        ) {
          this.config[type].ignore_subscription = 'doNotIgnore'
          this.config[type].consent_preference = {
            enabled: true,
            preference: '0'
          }
          this.updateConfig()
        } else if (
          !this.$store.getters.isFeatureEnabled('consentPreferences') &&
          this.action.config?.[type]?.consent_preference &&
          this.action.config?.[type]?.consent_preference.enabled &&
          this.action.config?.[type]?.consent_preference.preference === '0'
        ) {
          this.config[type].ignore_subscription = 'ignore'
          this.config[type].consent_preference = {
            enabled: false,
            preference: null
          }
          this.updateConfig()
        }
      })
    },

    updateConsentPreference(type, settings) {
      this.config[type].consent_preference.preference = settings.value
      this.config[type].consent_preference.preference_name = settings.name
      this.updateConfig()
    },

    changePreviewLanguage(value) {
      this.messagePreviewLanguage = value
    },

    generateKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      // This will make sure we go to the first tab as that will always be the default language tab
      this.panelKeyState += this.panelKeyState
      this.messagePreviewLanguage = ''
    },

    importTemplate(template, channel) {
      if (this.readOnly) {
        return
      }

      this.setDialogVisible = false
      const projectId = this.$store.state.app.project.id
      XpApi.get(`projects/${projectId}/campaign-templates/${template.id}`, {
        params: { fields: 'id,title', lang: this.getCurrentLanguage, expand: 'message' }
      }).then(({ data: template }) => {
        const message = template.message

        this.config[channel].messages[this.getCurrentLanguage].title = message.push_title
        this.config[channel].messages[this.getCurrentLanguage].text = message.push_text
        this.config[channel].pictureUrl = message.push_picture
        this.config[channel].pictureVariant = message.push_picture ? message.push_picture_type : 'none'
        this.config[channel].payload = message.payload_add

        if (channel === 'ios' || channel === 'android') {
          this.config[channel].defaultAction.type =
            message.push_action.action === PushActions.INAPP
            ? 'none'
            : message.push_action.action
          this.config[channel].defaultAction.value =
            message.push_action.action === 'url' ? message.push_action.url : message.push_action.deeplink
        }

        if (channel === 'web') {
          this.config[channel].iconUrl = message.push_icon
          this.config[channel].url = message.push_action.url
        }

        this.config.templateInfo = this.config.templateInfo || {}
        this.config.templateInfo[channel] = this.config.templateInfo[channel] || {}
        this.config.templateInfo[channel][this.getCurrentLanguage] = {
          templateId: template.id,
          templateTitle: template.title,
          whoApplied: this.$store.state.app.user.email,
          lastApplied: new Date().getTime()
        }

        const config = JSON.parse(JSON.stringify(this.config))
        this.workflowApi.updateAction(this.action.id, { config })
      })
    },

    formatTemplateTooltip(channel) {
      const whoApplied = this.action.config.templateInfo?.[channel]?.[this.getCurrentLanguage]?.whoApplied
      if (!whoApplied) {
        return ''
      }

      const lastApplied = moment(this.action.config.templateInfo?.[channel]?.[this.getCurrentLanguage]?.lastApplied)
        .format('DD/MMM/YYYY HH:mm')

      return this.$t('templateAppliedBy', {
        whoApplied: whoApplied,
        lastApplied: lastApplied
      })
    },

    formatTemplateInfo(channel) {
      const templateTitle = this.action.config.templateInfo?.[channel]?.[this.getCurrentLanguage]?.templateTitle
      if (!templateTitle) {
        return ''
      }

      return this.$t('templateCreatedFrom') + templateTitle
    },

    hasContent(channel) {
      if (!this.config[channel]?.hasOwnProperty('messages')) {
        return false
      }

      if (
        this.config[channel].messages[this.getCurrentLanguage]?.title
        || this.config[channel].messages[this.getCurrentLanguage]?.text
        || this.config[channel].pictureUrl
        || this.config[channel].payload?.length > 0
      ) {
        return true
      }

      if ((channel === 'ios' || channel === 'android') && this.config[channel].defaultAction.value) {
        return true
      }

      if ((channel === 'web') && (this.config[channel].iconUrl || this.config[channel].url)) {
        return true
      }

      return false
    }
  },

  watch: {
    'config.retry_type': {
      immediate: true,
      handler(value) {
        switch (value) {
          case 1:
            this.config.retry_until = null
            break
          case 2:
            this.config.retry_for = null
            this.config.retry_for_period = null
            break
          case 3:
            this.config.retry_for = null
            this.config.retry_for_period = null
            this.config.retry_until = null
            break
        }
      }
    },
    'config.exclusion': {
      handler() {
        this.updateConfig()
      },
      deep: true
    }
  }
}
</script>
<style lang="sass" scoped>
.el-autocomplete
  width: 50%
  padding-top: 2px

.flexbox
    display: flex
h3
    margin-top: 0
    color: #628DBF
    font-size: 18px

.web-push
    ::v-deep .icon
        width: unset
        height: unset

.setup-tab
    padding: 20px
    .multiselect, input
        width: 50%

.ignore-subscription-ct
    padding: 0 0 20px 0

.VoucherOption
    display: flex
    flex-direction: column
    padding-right: 0
    padding-left: 0
    margin-bottom: 15px

.device-toggle-ct
  display: flex
  flex-direction: column
  gap: 7px

.label-switch-group
  display: flex
  align-items: center
  gap: 100px

.new-ui-label-no-width
  color: #4b515f
  font-weight: 600

.new-ui-label
  color: #4b515f
  font-weight: 600
  width: 200px

.label-input-group
  display: flex
  flex-direction: column
  gap: 7px

.setup-tab-ct
  display: flex
  flex-direction: column
  gap: 20px
  width: 50%

.web-settings
  display: flex
  flex-direction: column
  gap: 20px

.push-icon
  display: flex
  justify-self: center
  width: 40px
  height: 40px
  margin-bottom: 20px

.preview-panel-ct
  margin-bottom: 20px

.action-btn
  display: flex
  color: #2563EB
  font-size: 14px
  font-weight: 700
  height: 40px
  gap: 10px
  align-items: center
  padding-bottom: 5px
  user-select: none

.el-popper
  margin-top: 0 !important

.template-summary
  user-select: none
  float: right
</style>
