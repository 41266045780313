<template>
  <Dropdown
    placeholder="Select Option"
    v-model="selectedOption"
    @change="updateValue"
    :disabled="disabled"
    :options="options"
    option-label="label"
  />
</template>

<script>
import Dropdown from 'primevue/dropdown'
export default {
  components: {
    Dropdown
  },

  props: {
    value: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.selectedOption = this.getSelectedOption
  },

  data() {
    return {
      selectedOption: null,
      options: [
        { id: 0, label: this.$t('selectBarcodePositionTopLabel') },
        { id: 1, label: this.$t('selectBarcodePositionBottomLabel') }
      ]
    }
  },

  computed: {
    getSelectedOption: function () {
      return this.options.find(({ id }) => id === this.value)
    }
  },
  methods: {
    updateValue(option) {
      this.$emit('input', option.value.id)
    }
  }
}
</script>
