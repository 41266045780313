<template>
  <div class="specific-date-ct">
    <div>
      <div class="label-input-group">
        <span class="label-new-ui">
          {{ $t('delayToolboxSmallSpecificDateLabel') }}
        </span>
        <div ref="dateTimePicker" class="input-group date">
          <input
            type="text"
            class="form-control"
            :disabled="readOnly"
            :placeholder="$t('delayToolboxSmallSpecificDateSelectPlaceholder')"
          />
          <span class="input-group-addon">
            <icon glyph="date" />
          </span>
          <span v-if="getProjectTimeZone" class="input-group-addon">
            {{ getProjectTimeZone }}
          </span>
        </div>
      </div>
      <div class="label-input-group">
        <span>
          <input
            ref="useTime"
            type="checkbox"
            :checked="action.options.useTime"
            :disabled="readOnly"
            @change="saveOptions"
          />
          {{ $t('delayToolboxSmallSpecificDateIncludeTimeCheckbox') }}
        </span>
        <span style="color: #b0b0b0">
          {{ $t('delayToolboxSmallSpecificDateIncludeTimeHelpBlock') }}
        </span>
      </div>
    </div>
    <div class="label-input-group">
      <span class="label-new-ui">
        {{ $t('delayToolboxSmallSpecificDateSelectTimezone') }}
      </span>
      <timezone-picker
        v-model="timezone"
        :disabled="readOnly"
        :bespoke-options="bespokeOptions"
        class="form-control"
        @input="saveOptions"
      />
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import '@/libs/bootstrap-datepicker/bootstrap-datetimepicker'
// moment-timezone has to be imported to allow timezone functionality to work with datetimepicker
import moment from 'moment-timezone'
import TimezonePicker from '@/components/TimezonePicker'

export default {
  components: { TimezonePicker },
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  data() {
    return {
      timezone: this.action.options.timezone || 'profile',
      bespokeOptions: {
        profile: this.$t('timeZoneProfileTimezone')
      }
    }
  },

  computed: {
    getProjectTimeZone() {
      return this.$store.state.app.project.projectTimezone || ''
    }
  },

  watch: {
    'action.options.useTime': 'updateDateTimePicker',
    'action.options.delayUntil': 'updateDateTimePicker'
  },

  mounted() {
    $(this.$refs.dateTimePicker)
      .datetimepicker({
        timeZone: this.getProjectTimeZone,
        format: 'D MMMM YYYY',
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'bottom'
        }
      })
      .on('dp.hide', this.saveOptions)

    this.dateTimePicker = $(this.$refs.dateTimePicker).data('DateTimePicker')

    this.updateDateTimePicker()
  },

  beforeDestroy() {
    this.dateTimePicker.destroy()
    $(this.$refs.dateTimePicker).off()
  },

  methods: {
    updateDateTimePicker() {
      this.preventSave = true

      let format = 'D MMMM YYYY'
      if (this.action.options.useTime) {
        format += ' h:mm A'
      }
      this.dateTimePicker.format(format)

      if (this.action.options.delayUntil) {
        this.dateTimePicker.date(new Date(this.action.options.delayUntil))
      }

      this.preventSave = false
    },

    getDelayUntil(useTime) {
      const date = this.dateTimePicker.date()

      if (!date) {
        return ''
      }

      return useTime
        ? date.format('YYYY-MM-DD HH:mm')
        : date.format('YYYY-MM-DD')
    },

    saveOptions() {
      if (this.preventSave) return

      const options = {
        type: 'specificDate',
        useTime: this.$refs.useTime.checked,
        delayUntil: this.getDelayUntil(this.$refs.useTime.checked),
        timezone: this.timezone
      }

      if (JSON.stringify(options) === JSON.stringify(this.action.options))
        return

      this.workflowApi.updateAction(this.action.id, {
        options
      })
    }
  }
}
</script>
<style scoped>
.specific-date-ct {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-group-addon {
  background-color: #ffffff;
}

.label-new-ui {
  color: #4b515f;
  font-weight: 600;
}

.label-input-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.help-block {
  margin-bottom: 0;
}
</style>
