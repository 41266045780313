<template>
  <Dropdown
    :placeholder="$t('selectOptionPlaceholder')"
    :options="options"
    :disabled="disabled"
    @change="updateValue"
    :value="selectedOption"
    option-label="label"
  />
</template>

<script>
import Dropdown from 'primevue/dropdown'
export default {
  components: {
    Dropdown
  },

  props: {
    value: {
      type: Number
    },
    disabled: {
      type: Boolean
    }
  },

  emits: ['input'],

  data() {
    return {
      options: [
        { id: 1, label: this.$t('selectRetryTypeRetryForOption') },
        { id: 2, label: this.$t('selectRetryTypeRetryUntilOption') },
        { id: 3, label: this.$t('selectRetryTypeDoNotRetry') }
      ]
    }
  },

  computed: {
    selectedOption: function () {
      return this.options.find(({ id }) => id === this.value)
    }
  },
  methods: {
    updateValue: function ({ value }) {
      this.$emit('input', value.id)
    }
  }
}
</script>
