<template>
  <MultiSelect
    :disabled="disabled"
    :placeholder="placeholder"
    @change="onChange"
    v-model="selectedLanguages"
    :options="options"
    filter
    option-label="name"
    display="chip"
  />
</template>
<script>
import ISO6391 from 'iso-639-1'
import MultiSelect from 'primevue/multiselect'

export default {
  components: {
    MultiSelect
  },
  props: {
    value: {
      type: Array
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$t('selectALanguageLabel')
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultLanguageCode: {
      type: [String, null],
      required: false,
      default: ''
    }
  },

  data() {
    return {
      selectedLanguages: null
    }
  },

  mounted() {
    if (this.objectValue.length) {
      this.selectedLanguages = this.objectValue
    }
  },

  computed: {
    objectValue() {
      return this.value.map((value) => {
        return this.options.find((option) => option.code === value)
      })
    },

    options() {
      if (!this.defaultLanguageCode) {
        return ISO6391.getLanguages(ISO6391.getAllCodes())
      }
      return ISO6391.getLanguages(ISO6391.getAllCodes()).filter(
        (language) => language.code !== this.defaultLanguageCode
      )
    }
  },

  methods: {
    onChange(options) {
      this.$emit(
        'input',
        options.value.map((option) => option.code)
      )
    }
  }
}
</script>
<style scoped>
::v-deep .multiselect__select {
  height: 40px;
}
</style>
