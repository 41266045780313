<template>
  <div class="control-bar">
    <div class="control-buttons">
      <div class="control-button" @click="$emit('save', false)">
        <i class="el-icon-check" />
        Save
      </div>
      <div class="control-button" @click="$emit('save', true)">
        <i class="el-icon-check" />
        Save and Close
      </div>
      <div class="control-button" @click="$emit('discard')">
        <i class="el-icon-close" />
        Discard
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolbarEditor',

  props: {
    saveVisible: { default: true, type: Boolean },
    saveLabel: {
      default: function () {
        return this.$t('toolbarSave')
      }
    },

    discardVisible: { default: true, type: Boolean },
    discardLabel: {
      default: function () {
        return this.$t('toolbarDiscard')
      }
    }
  }
}
</script>

<style scoped>
.control-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  background: #505659;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.control-buttons {
  float: right;
}
.control-button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  float: left;
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  color: #ffffff;
  padding: 0 5px;
  margin-right: 20px;
}
.control-button:hover {
  opacity: 0.8;
}
</style>
