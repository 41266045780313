<template>
  <div class="lock-screen" :class="{ 'with-background': background }">
    <div class="time">{{ time }}</div>
    <div class="date">{{ date }}</div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import '@/fonts/HelveticaNeue/stylesheet.css'
import moment from 'moment'

export default {
  name: 'LockScreenIos',

  props: {
    background: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    time: function() {
      return moment().format('HH:mm')
    },
    date: function() {
      return moment().format('dddd, D MMMM')
    }
  }
}
</script>

<style scoped>
.lock-screen {
  height: 100%;
  width: 100%;
  background-color: #083146;
  font-family: 'Helvetica Neue', sans-serif;
  padding-top: 50px;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
.with-background {
  background-color: #7371ee;
  background-image: linear-gradient(60deg, #7371ee 1%, #a1d9d6 100%);
}
.time {
  text-align: center;
  font-size: 60px;
  font-weight: 100;
  color: #ffffff;
}
.date {
  text-align: center;
  font-size: 20px;
  font-weight: 100;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content {
  padding: 10px;
}
</style>
