<template>
  <div>
    <date-input
      v-model="date"
      :placeholder="$t('selectDatePlaceholder')"
      class="condition date-input"
      @input="onInput"
      :disabled="readOnly"
    />
    <timezone-picker
      v-if="futureDateOnly"
      v-model="timezone"
      :bespoke-options="bespokeOptions"
      class="condition"
      @input="onInput"
      :read-only="readOnly"
    />
  </div>
</template>
<script>
import DateInput from '@/components/DateInput'
import TimezonePicker from '@/components/TimezonePicker'

export default {
  components: { TimezonePicker, DateInput },
  props: ['value', 'isFutureDateOnly', 'readOnly'],

  data() {
    return {
      date: this.value.value || '',
      timezone: this.value.timezone || 'profile',
      futureDateOnly: this.isFutureDateOnly || false,
      bespokeOptions: {
        profile: 'Profile Timezone'
      }
    }
  },

  methods: {
    onInput() {
      const args = {
        value: this.date
      }

      if (this.futureDateOnly) {
        args.timezone = this.timezone ? this.timezone : 'profile'
      }
      this.$emit('input', args)
    }
  }
}
</script>
