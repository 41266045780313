<template>
  <div class="push-preview-ios" :class="expanded ? 'expanded' : ''">
    <div class="message" @click="openMore">
      <div class="header">
        <div class="app-icon"><img :src="appIcon" /></div>
        <div class="app-name">
          {{ appName ? appName : $t('devicePreviewAppNamePlaceholder') }}
        </div>
        <div v-if="!expanded" class="date" @click="closeMore">now</div>
        <div v-if="expanded" class="close" @click="closeMore">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div v-if="showPicture" class="picture">
        <img :src="message.picture" />
      </div>
      <div class="content-container" :class="expanded ? 'expanded' : ''">
        <div class="content">
          <div class="title">{{ messageTitle }}</div>
          <div class="text" style="white-space: pre-wrap">
            <span>{{ messageText }}</span>
          </div>
        </div>
        <div v-if="showIcon" class="icon"><img :src="message.picture" /></div>
      </div>
    </div>
    <div v-if="showActions" class="actions">
      <div v-for="action in visibleActions" class="action">{{ action }}</div>
    </div>
  </div>
</template>

<script>
import '@/fonts/HelveticaNeue/stylesheet.css'

export default {
  name: 'PushPreviewIos',

  props: {
    message: Object,
    appIcon: String,
    appName: String
  },

  emits: ['contentExpanded'],

  data() {
    return {
      expanded: false
    }
  },

  computed: {
    visibleActions: function () {
      if (this.message.actions) {
        return this.message.actions
      } else {
        return []
      }
    },

    showActions: function () {
      return this.expanded && this.visibleActions.length
    },

    showPicture: function () {
      return this.expanded && this.message.picture
    },

    showIcon: function () {
      return !this.expanded && this.message.picture
    },

    messageTitle: function () {
      return this.message.title
        ? this.message.title
        : this.$t('devicePreviewTitlePlaceholder')
    },

    messageText: function () {
      return this.message.text
        ? this.message.text
        : this.$t('devicePreviewTextPlaceholder')
    }
  },

  watch: {
    expanded: function () {
      this.$emit('contentExpanded')
    }
  },

  methods: {
    openMore: function () {
      this.expanded = true
    },

    closeMore: function (event) {
      this.expanded = false
      event.stopPropagation()
    }
  }
}
</script>

<style scoped lang="scss">
.message {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: saturate(180%) blur(20px);
  border-radius: 10px;
  font-family: 'Helvetica Neue', sans-serif;
  cursor: pointer;
  position: relative;
  text-align: left;
}

.expanded .message {
  cursor: default;
  background: #ffffff;
}

.header {
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 9px;
}

.expanded .header {
  background: #fff;
  border-bottom: 1px solid #bdbdbd;
}

.app-icon {
  font-size: 0;
}

.app-icon img {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.app-name {
  flex: 1;
  text-transform: uppercase;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #60596a;
  font-size: 12px;
}

.date {
  line-height: 18px;
  font-size: 11px;
  margin-right: 6px;
}

.close {
  cursor: pointer;
  font-size: 16px;
}

.content-container {
  font-size: 13px;
  display: flex;
  padding: 0 10px 6px;

  &.expanded {
    padding: 10px;
  }
}

.content {
  flex: 1;
  min-width: 0;
}

.title {
  color: #000000;
  margin-bottom: 3px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 17px;
  white-space: nowrap;
}

.text {
  color: #141414;
  max-height: 61px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text p {
  font-size: 12px;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
}

.picture {
  text-align: center;
}

.picture img {
  max-width: 100%;
}

.icon {
  margin-left: 10px;
}

.icon img {
  width: 32px;
  height: 32px;
}

.actions {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: saturate(180%) blur(20px);
  border-radius: 10px;
  cursor: default;
  position: relative;
  text-align: center;
}

.action {
  height: 40px;
  line-height: 40px;
  color: #000000;
  font-size: 13px;
  border-bottom: 1px solid rgba(100, 100, 100, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}

.action:last-child {
  border: 0 none;
}
</style>
