<template>
  <div class="card">
    <div class="card__browser-icon">
      <img src="@/images/firefox.png" />
    </div>
    <div class="card__app-name">{{ $t('devicePreviewFirefox') }}</div>
    <div class="card__timestamp">
      {{ $t('devicePreviewTextNow').toLowerCase() }}
    </div>
    <div class="card__title">
      <span>{{ renderedTitle }}</span>
    </div>
    <div class="card__domain">
      {{ $t('devicePreviewVia').toLowerCase() }}
      {{ $t('devicePreviewTextExampleWebsite') }}
    </div>
    <div class="card__text" style="white-space: pre-wrap;">
      <span>{{ renderedMessage }}</span>
    </div>
    <div class="card__icon" v-if="renderedIcon !== null">
      <img :src="icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PushPreviewWebMacFirefox',

  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    icon: {
      type: String
    }
  },
  computed: {
    renderedTitle() {
      if (this.title === '' || this.title === null) {
        return this.$t('devicePreviewTextNotificationPreview')
      } else {
        return this.title
      }
    },
    renderedMessage() {
      if (this.message === '' || this.message === null) {
        return this.$t('devicePreviewTextNotificationPreviewMessage')
      } else {
        return this.message
      }
    },
    renderedIcon() {
      if (this.icon === '' || this.icon === null) {
        return null
      } else {
        return this.icon
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  position: relative;
  width: 344px;
  height: 110px;
  background: rgba(245, 245, 245, 0.6);
  box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(33.2019px);
  border-radius: 10px;

  &__browser-icon {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 9px;
    top: 9px;

    & > img {
      width: 18px;
      min-width: 18px;
      max-width: 18px;
      min-height: 18px;
      max-height: 18px;
      height: 18px;
    }
  }

  &__app-name {
    position: absolute;
    height: 18px;
    left: 32px;
    right: 82px;
    top: 9px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.006em;
    text-transform: uppercase;
    color: #797979;
  }

  &__timestamp {
    position: absolute;
    width: 66px;
    height: 16px;
    right: 12px;
    top: 10px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #797979;
    justify-content: flex-end;
  }

  &__title {
    position: absolute;
    height: 23px;
    left: 11px;
    right: 46px;
    top: 28px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #232526;

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__domain {
    position: absolute;
    left: 11px;
    right: 11px;
    top: 50px;
    bottom: 12px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #232526;
  }

  &__text {
    position: absolute;
    left: 11px;
    right: 46px;
    top: 67px;
    bottom: 11px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #232526;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__icon {
    position: absolute;
    width: 34px;
    height: 34px;
    right: 8px;
    top: 30px;
    border-radius: 4px;

    & > img {
      width: 34px;
      min-width: 34px;
      max-width: 34px;
      min-height: 34px;
      max-height: 34px;
      height: 34px;
      background-color: rgb(254, 254, 254);
      border-radius: 4px;
    }
  }
}
</style>
