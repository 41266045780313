<template>
  <div class="sidebar sidebar-lg">
    <div class="sidebar-header action-style-workflowreporting">
      <action-icon
        class="sidebar-icon"
        action-type="WorkflowReporting"
        size="38"
      />
      <div class="sidebar-title">{{ $t('workflowReporting') }}</div>
      <button
        type="button"
        class="close"
        :aria-label="$t('closeButton')"
        @click="$emit('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="sidebar-body">
      <TabView :active-index.sync="activeTabIndex">
        <TabPanel header="Workflow">
          <workflow-started
            v-bind="{
              startedTotal,
              completedTotal,
              dateRange,
              startedStats,
              completedStats
            }"
          />
          <workflow-stats
            v-bind="{
              startedTotal,
              completedTotal,
              waitingTotal,
              emailArrivedTotal,
              emailSentTotal,
              emailDeliveredTotal,
              emailBouncesTotal,
              emailUniqueOpensTotal,
              emailUniqueClicksTotal,
              emailUnsubscribesTotal,
              smsArrivedTotal,
              smsSentTotal,
              pushArrivedTotal,
              pushSentTotal,
              pushDeliveredTotal,
              pushClickedTotal,
              channels,
              workflow
            }"
          />
        </TabPanel>
        <TabPanel v-if="channels.push" header="Push">
          <push-stats
            v-bind="{
              dateRange,
              pushArrivedTotal,
              pushSentTotal,
              pushDeliveredTotal,
              pushClickedTotal,
              pushSentStats,
              pushClickedStats
            }"
          />
        </TabPanel>
        <TabPanel v-if="channels.email" header="Email">
          <email-delivery
            v-bind="{
              emailArrivedTotal,
              emailSentTotal,
              emailDeliveredTotal,
              emailBouncesTotal,
              dateRange,
              emailSentStats
            }"
          />
          <email-engagement
            v-bind="{
              emailDeliveredTotal,
              emailUniqueOpensTotal,
              emailUniqueClicksTotal,
              emailUnsubscribesTotal,
              dateRange,
              emailSentStats,
              emailUniqueOpensStats,
              emailUniqueClicksStats,
              emailUnsubscribesStats,
              workflow
            }"
          />
        </TabPanel>
        <TabPanel v-if="channels.sms" header="SMS">
          <sms-delivery
            v-bind="{
              smsArrivedTotal,
              smsSentTotal,
              dateRange,
              smsSentStats,
              smsUnsubscribesStats
            }"
          />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import MxaApi from '@/libs/MxaApi'

import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'

import WorkflowStats from './WorkflowReporting/WorkflowStats'
import WorkflowStarted from './WorkflowReporting/WorkflowStarted'
import EmailDelivery from './WorkflowReporting/EmailDelivery'
import EmailEngagement from './WorkflowReporting/EmailEngagement'
import SmsDelivery from './WorkflowReporting/SmsDelivery'
import PushStats from './WorkflowReporting/PushStats'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'

export default {
  props: ['workflow'],

  components: {
    WorkflowStats,
    WorkflowStarted,
    EmailDelivery,
    EmailEngagement,
    SmsDelivery,
    PushStats,
    TabPanel,
    TabView
  },

  mixins: [ReportingMixin, TrendLineMixin],

  data() {
    return {
      stats: {},
      channels: {
        email: false,
        push: false,
        sms: false
      },
      activeTabIndex: 0
    }
  },

  created() {
    const projectId = this.$route.params.projectId,
      workflowId = this.$route.params.workflowId

    const endDate = moment().format('YYYY-MM-DD'),
      startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')

    MxaApi.get(`projects/${projectId}/workflows/${workflowId}/summary`, {
      params: {
        startDate,
        endDate
      }
    }).then(({ data }) => {
      this.stats = data

      // Set channel visibility flags. Shows reporting for existing action types and all actions from AAS table
      this.workflow.actions.forEach((action) => {
        switch (action.type) {
          case 'Email':
            this.channels.email = true
            break
          case 'Sms':
            this.channels.sms = true
            break
          case 'Push':
            this.channels.push = true
            break
        }
      })

      if (data) {
        Object.keys(data).some(
          (stat) => (this.channels.email = stat.startsWith('Email'))
        )
        Object.keys(data).some(
          (stat) => (this.channels.sms = stat.startsWith('Sms'))
        )
        Object.keys(data).some(
          (stat) => (this.channels.push = stat.startsWith('Push'))
        )
      }
    })
  },

  totalsMapping: {
    startedTotal: 'Start:entered',
    completedTotal: 'End:completed',
    delayedTotal: 'Delay:delayed',
    resumedTotal: 'Delay:resumed',
    emailSentTotal: 'Email:sent',
    emailNotSentTotal: 'Email:failed',
    emailUniqueOpensTotal: 'Email:openedUnique',
    emailUniqueClicksTotal: 'Email:clickedUnique',
    emailUnsubscribesTotal: 'Email:unsubscribed',
    smsSentTotal: 'Sms:sent',
    smsNotSentTotal: 'Sms:notSent',
    pushArrivedTotal: 'Push:trigger',
    pushSentTotal: 'Push:sent',
    pushFailedTotal: 'Push:failed',
    pushClickedTotal: 'Push:opened',
    adRetargetTotal: 'AdRetarget:trigger'
  },

  statsMapping: {
    startedStats: 'Start:entered',
    completedStats: 'End:completed',
    emailSentStats: 'Email:sent',
    emailUniqueOpensStats: 'Email:openedUnique',
    emailUniqueClicksStats: 'Email:clickedUnique',
    emailUnsubscribesStats: 'Email:unsubscribed',
    smsSentStats: 'Sms:sent',
    smsUnsubscribesStats: 'Sms:unsubscribes',
    pushSentStats: 'Push:sent',
    pushClickedStats: 'Push:opened',
    adRetargetTotal: 'AdRetarget:trigger'
  },

  computed: {
    waitingTotal() {
      return (
        this.delayedTotal -
        this.resumedTotal -
        this.getTotal('Delay:cancelled') -
        this.getTotal('Delay:earlyCancel')
      )
    },

    emailArrivedTotal() {
      return this.emailSentTotal + this.emailNotSentTotal
    },

    emailBouncesTotal() {
      return (
        this.getTotal('Email:bouncesSoft') + this.getTotal('Email:bouncesHard')
      )
    },

    emailDeliveredTotal() {
      return this.emailSentTotal - this.emailBouncesTotal
    },

    smsArrivedTotal() {
      return this.smsSentTotal + this.smsNotSentTotal
    },

    pushDeliveredTotal() {
      return this.pushSentTotal - this.pushFailedTotal
    }
  }
}
</script>
<style lang="sass" scoped>
::v-deep .p-tabview .p-tabview-panels
  color: #707070
  padding: 0
.sidebar-header
    background-color: $light-blue

.sidebar
  overflow: scroll
</style>
