<template>
  <div :class="rootClass">
    <span class="new-ui-label">{{ $t('ignoreSubscriptionTitle') }}</span>
    <InputSwitch :disabled="readOnly" v-model="ignoreSubscription"/>
  </div>
</template>

<script>
import InputSwitch from 'primevue/inputswitch';
export default {
  name: 'IgnoreSubscription',
  components: {InputSwitch},
  props: {
    value: {
      required: true,
      default: 'doNotIgnore'
    },
    readOnly: {
      required: true
    },
    rootClass: {
      required: false,
      default: 'col-md-12'
    }
  },
  data() {
    return {
      ignoreSubscription: false
    }
  },

  mounted() {
    if (this.value === 'default') {
      this.$emit('input', 'doNotIgnore')
    }

    this.ignoreSubscription = this.value === 'ignore'
  },

  watch:{
    ignoreSubscription(value) {
      if (this.readOnly) {
        return
      }

      if (value) {
        this.$emit('input', 'ignore')
      } else {
        this.$emit('input', 'doNotIgnore')
      }
    }
  }
}
</script>

<style scoped>
.new-ui-label {
  color: #4b515f;
  font-weight: 600;
  width: 200px;
}
</style>
