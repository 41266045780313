<template>
  <div class="card">
    <div class="card__icon" v-if="renderedIcon !== null">
      <img :src="icon" />
    </div>
    <div class="card__content">
      <div class="card__title">
        {{ renderedTitle }}
      </div>
      <div class="card__text" style="white-space: pre-wrap;">
        <span>{{ renderedMessage }}</span>
      </div>
      <div class="card__sender">
        {{ $t('devicePreviewChrome') }} •
        {{ $t('devicePreviewTextExampleWebsite') }}
      </div>
      <div v-if="renderedPicture" class="card__picture">
        <img :src="renderedPicture" />
      </div>
      <div class="actions">
        <div v-for="action in actions" class="action">{{ action }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PushPreviewWebWindowsChrome',

  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    icon: {
      type: String
    },
    picture: {
      type: String
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    renderedTitle() {
      if (this.title === '' || this.title === null) {
        return this.$t('devicePreviewTextNotificationPreview')
      } else {
        return this.title
      }
    },
    renderedMessage() {
      if (this.message === '' || this.message === null) {
        return this.$t('devicePreviewTextNotificationPreviewMessage')
      } else {
        return this.message
      }
    },
    renderedIcon() {
      if (this.icon === '' || this.icon === null) {
        return null
      } else {
        return this.icon
      }
    },
    renderedPicture() {
      if (this.picture === '' || this.picture === null) return null

      return this.picture
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');
.card {
  display: flex;
  flex-direction: row;
  width: 364px;
  box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.18);
  background: #1f1f1f;
  position: relative;
  padding: 15px;

  &__icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    flex: 1;

    & > img {
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
      height: 50px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 4;
    max-width: 100%;
    min-width: 0;
  }

  &__title {
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__text {
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #a0a0a0;
    text-overflow: ellipsis;
  }

  &__sender {
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #999999;
  }

  &__picture {
    margin: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    img {
      max-height: 220px;
      max-width: 100%;
    }
  }
  .actions {
    margin-top: 10px;
    padding: 0 4px;
    display: flex;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .actions .action {
    white-space: nowrap;
    background-color: #ffffff40;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    color: #ffffff;
    margin-right: 7px;
    font-size: 12px;
  }
}
</style>
